import React from 'react';
import Hibiya from './Hibiya';
import HibiyaEng from './HibiyaEng';
import NipponKoei from './NipponKoei';
import Njs from './Njs';
import Ricoh360 from './Ricoh360';
import Ricoh360Prjects from './Ricoh360Projects';
import Ricoh360Tours from './Ricoh360Tours';
import RicohTHETA from './RicohTHETA';
import Shimizu from './Shimizu';
import ShimizuEng from './ShimizuEng';
import Smcr from './Smcr';

type Props = {
  name: string;
};

interface LOGOS {
  [key: string]: React.FC;
}

const LOGOS: LOGOS = {
  HIBIYA: Hibiya,
  HIBIYAENG: HibiyaEng,
  NIPPONKOEI: NipponKoei,
  NJS: Njs,
  SMCR: Smcr,
  SHIMIZU: Shimizu,
  SHIMIZUENG: ShimizuEng,
  others: Ricoh360,
  theta: RicohTHETA,
  tours: Ricoh360Tours,
  projects: Ricoh360Prjects,
};

const Logo: React.FC<Props> = ({ name }) => {
  const LogoItem = LOGOS[name];
  return <LogoItem />;
};

export default Logo;
