import { graphql, useStaticQuery } from 'gatsby';

export type Data = GatsbyTypes.SiteSiteMetadata | Record<string, never>;

const useSiteMetadata = (): Data => {
  const { site } = useStaticQuery<GatsbyTypes.SiteMetadataQuery>(
    graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            title
            description
            siteUrl
            loginUrl
            statusUrl
            signupUrl
            supportUrl
            checkoutUrl
            twitterUrl
            facebookUrl
            projectsSignupUrl
            projectsSupportUrl
            projectsContactUrl
            projectsLoginUrl
          }
        }
      }
    `,
  );

  if (!site?.siteMetadata) {
    // eslint-disable-next-line no-console
    console.error('No site metadata');
    return {};
  }

  return site.siteMetadata;
};

export default useSiteMetadata;
