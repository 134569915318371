import React from 'react';
import styled from 'styled-components';

const SMCR = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.svg`
  margin-right: 16px;
`;

const Smcr: React.FC = () => (
  <SMCR>
    <Logo
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 77.58 73.7"
      height={45}
    >
      <linearGradient
        id="linear-gradient"
        x1="15%"
        y1="15%"
        x2="120%"
        y2="120%"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ffd900" />
        <stop offset=".12" stopColor="#fcc601" />
        <stop offset=".36" stopColor="#f79605" />
        <stop offset=".7" stopColor="#ee490b" />
        <stop offset="1" stopColor="#e60012" />
      </linearGradient>
      <g style={{ fill: 'url(#linear-gradient)' }}>
        <path d="M38.64,63.45c.38-.06,.76-.12,1.13-.17v-18.63c-.37,.06-.75,.12-1.13,.19v18.62h0Z" />
        <path d="M33.54,31.65c-.3,.09-.61,.18-.91,.27v11.84c.3-.07,.61-.14,.91-.21v-11.9Z" />
        <path d="M46.55,62.14c7.08-1.26,12.54-2.53,14.73-3.57,1.46-.7,6.3-3.12-9.83-3.73l.02-.19c7.96-.06,15.75,.58,16.16,2.21,.73,2.94-9.03,5.74-21.08,8.03v8.06c16.19-3.13,28.71-15.84,30.73-31.62-11.21,.13-25.95,1.74-30.73,2.33v18.48Z" />
        <path d="M29.54,53.14v-6.45c-3.43,.83-6.46,1.79-8.14,2.82-1.09,.66-4.76,2.81,8.14,3.64h0Z" />
        <path d="M32.63,45.99v18.34c.3-.04,.61-.09,.91-.13v-18.41c-.3,.07-.61,.13-.91,.19h0Z" />
        <path d="M38.64,42.55c.38-.07,.75-.13,1.13-.19v-12.41c-.38,.09-.75,.19-1.13,.29v12.32h0Z" />
        <path d="M29.54,53.38c-6.47-.09-12.83-.81-13.24-2.35-.56-2.12,5.54-4.58,13.24-6.53v-11.62c-2.3,.74-4.48,1.52-6.51,2.32-9.03,3.53-15.37,8.61-14.7,12.01,.4,2.02,2.43,3.5,5.47,4.36,0,0-.06,.16-.07,.16-3.87-.56-7.25-2.11-7.72-4.52-1.14-5.79,8.76-11.84,19.1-15.77,1.48-.56,2.96-1.1,4.43-1.61V12.3c0-1.18,1.2-2.52,3.1-3.73V28.8c.3-.1,.61-.19,.91-.29V8.05c1.45-.81,3.2-1.53,5.1-2.05V26.98c.38-.11,.75-.21,1.13-.32V5.64c1.48-.35,3.03-.58,4.58-.64,.98-.04,2.19-.09,2.19,.65V24.93c5.29-1.23,10.33-2.15,14.98-2.85l3.75,2.89c-6.45,.92-12.76,2.06-18.73,3.38v13.02c5.43-.72,19.2-2.43,30.98-2.76,.03-.58,.05-1.17,.05-1.76C77.58,16.5,60.21,0,38.79,0S0,16.5,0,36.85c0,12.11,6.15,22.84,15.64,29.55,4.6-.48,9.31-1.04,13.9-1.64v-11.37h0Z" />
        <path d="M32.63,67.21v6.02c.3,.05,.61,.08,.91,.12v-6.28c-.3,.05-.61,.09-.91,.14Z" />
        <path d="M38.64,66.28v7.41h.13c.34,0,.66-.02,1-.02v-7.58c-.38,.07-.75,.13-1.13,.18h0Z" />
        <path d="M19.73,68.94c3.04,1.63,6.33,2.89,9.8,3.7v-4.98c-3.43,.48-6.75,.91-9.8,1.28Z" />
      </g>
    </Logo>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 255.93 52.49"
      height={32}
    >
      <path d="M13.31,33.8v-2.77h3.15v2.77h4.45v3.12h-4.45v2.09h4.8v3.15h-3.89c1.61,2.84,3.37,4.09,4.29,4.71l-1.3,3.72c-1.72-1.34-2.99-3.15-3.9-5.1v6.36h-3.15v-6.86c-.99,2.13-2.42,4.05-4.16,5.65l-1.94-3.1c2.53-1.63,3.85-3.48,4.93-5.37h-3.85c.48,.62,.68,.82,.99,1.1l-1.89,2.51c-.92-1.74-1.03-2.18-1.25-3.08v9.15H3.1v-8.32c-.75,2.53-1.56,3.78-2.07,4.53l-1.03-4.33c1.3-1.63,1.89-2.9,2.86-6.03H.42v-3.04H3.1v-3.63h3.04v3.63h2.09v1.34c1.06-2.05,1.32-3.9,1.41-4.58l2.95,.29c-.09,.55-.2,1.1-.42,2.09h1.14Zm-3.46,5.21l-1.8-1.32h-1.91v.97c.42,1.01,1.19,2.22,2.09,3.41v-3.06h1.63Zm3.46,0v-2.09h-2.09c-.44,1.06-.9,1.8-1.1,2.09h3.19Z" />
      <path d="M23.41,47.93c.13-.02,3.7-.26,4.09-.31v-5.28h-3.5v-3.19h10.3v3.19h-3.43v5.02c2.46-.26,3.46-.44,4.2-.57l.04,3.12c-2.49,.51-3.54,.62-11.35,1.47l-.35-3.46Zm18.86-13.88h2.09v3.19h-6.14c.13,2.11,.37,6.05,1.63,8.93,.13,.33,.62,1.47,1.17,1.47,.59,0,1.78-1.52,2.31-2.99l1.39,3.5c-.35,.99-1.45,3.74-3.63,3.74-1.01,0-2.07-.57-2.66-1.23-3.19-3.48-3.43-9.81-3.56-13.42h-11.27v-3.19h11.16c-.02-.35-.11-2.9-.11-3.06h3.52v3.06h2.16c-.57-.84-1.01-1.5-1.61-2.05l2.84-1.14c.33,.24,.62,.44,2.11,2.57l-1.39,.62Z" />
      <path d="M51.22,38.29c-1.74,1.06-2.53,1.39-3.35,1.69l-1.67-3.34c5.37-2.11,7.66-3.96,8.96-5.79h3.92c.95,1.12,3.19,3.61,8.85,5.74l-1.72,3.32c-.66-.29-1.72-.75-3.28-1.7v1.34h-11.71v-1.28Zm12.65,13.77c-.22-.42-.46-.88-.88-1.54-3.74,.53-11.22,.84-15.18,.95l-.53-3.41c.11,.02,2.9,.02,3.21,.02,.18-.35,.9-1.83,1.54-3.81h-4.91v-3.17h19.85v3.17h-4.55c.99,1.08,2.46,2.68,4.62,6.14l-3.17,1.65Zm-3.34-15.45c-1.91-1.43-2.86-2.49-3.43-3.1-.53,.68-1.39,1.61-3.39,3.1h6.82Zm1.36,7.66h-6.18c-.79,1.85-1.06,2.42-1.74,3.81,2.6-.02,4.97-.02,7.17-.24-.92-1.23-1.41-1.8-1.85-2.33l2.6-1.23Z" />
      <path d="M75.9,51.86h-3.34v-7.33c-.9,.77-1.7,1.32-2.33,1.76l-.84-3.9c2.46-1.47,4.49-3.85,5.21-5.28h-4.82v-3.08h2.77v-3.01h3.34v3.01h2.24v3.08c-.33,.81-.68,1.63-1.76,3.19,1.21,1.32,2.22,1.92,3.01,2.36l-1.65,3.15c-.46-.44-1.06-.99-1.85-2.11v8.16Zm6.54-20.84h3.43v5.46h4.29v3.39h-4.29v8.05h4.62v3.34h-13.09v-3.34h5.04v-8.05h-3.96v-3.39h3.96v-5.46Z" />
      <path d="M119.78,33.49c0-3.11-.52-4.06-5.95-4.06-3.61,0-6.27,0-6.27,3.22,0,1.4,.53,2.38,2.98,2.62l8.64,.81c7.28,.66,7.39,5.99,7.39,7.81,0,7.84-5.53,8.61-12.01,8.61-10.18,0-12.64-1.86-12.71-9.8h5.78c0,3.47,0,4.62,6.44,4.62,4.41,0,6.83,0,6.83-3.36,0-1.99-.98-2.8-2.77-2.97l-10.01-.98c-5.04-.49-6.09-4.24-6.09-7.42,0-7.74,6.06-8.23,11.45-8.23,10.89,0,11.97,3.05,12.04,9.14h-5.74Z" />
      <path d="M161.01,52.21h-5.53l-7.07-21.81c.07,1.89,.21,5.98,.21,7.7v14.11h-5.53V24.63h8.4l6.76,20.9,6.76-20.9h8.4v27.58h-5.53v-14.11c0-1.93,.14-5.71,.21-7.7l-7.07,21.81Z" />
      <path d="M209,34.64c-.04-4.55-1.96-5.04-6.55-5.04-6.16,0-7.11,.63-7.11,8.58s.38,9.07,6.76,9.07,6.93-.49,7-5.64h5.81c-.07,7.63-2.1,10.89-11.94,10.89s-13.41-1.92-13.41-13.62c0-10.08,1.16-14.53,12.36-14.53,8.3,0,12.71,1.23,12.78,10.29h-5.71Z" />
      <path d="M247.7,24.63c2.45,0,7.98,0,7.98,7.63,0,1.99-.11,5.67-4.03,6.97,2.62,.63,3.61,2.49,3.61,5.22v2.52c0,2.94,.45,4.59,.66,5.25h-5.95c-.32-1.29-.42-2.94-.42-4.24v-2.66c0-3.47-1.96-3.47-3.74-3.47h-8.93v10.36h-5.77V24.63h16.59Zm-10.82,5.25v6.86h9.7c2.17,0,3.4-.67,3.4-3.71,0-2.59-1.4-3.15-3.33-3.15h-9.77Z" />
      <path d="M19.13,17.68H.61v-1.7H19.13v1.7Zm-.94-14.2H1.55V1.8H18.19v1.68Zm-1.02,6.76H2.57v-1.66h14.6v1.66Z" />
      <path d="M25.31,.46h1.66v3.86h6.06V.46h1.64v3.86h4.2v1.58h-4.2v5.28h4.52v1.58h-4.52v6.2h-1.64v-6.2h-6.52c-.42,1.74-1.28,3.86-4.8,6.2l-1.14-1.36c3.08-1.96,3.88-3.66,4.22-4.84h-4.14v-1.58h4.5c.16-.96,.16-1.96,.16-2.1v-3.18h-4.32v-1.58h4.32V.46Zm1.66,8.56c0,.46-.06,1.4-.16,2.16h6.22V5.9h-6.06v3.12Z" />
      <path d="M44.39,18.94h-1.62V8.56c-.72,1.14-1.18,1.6-1.68,2.1l-.84-1.88c1.8-1.84,3.44-5.16,4.12-8.42l1.62,.28c-.16,.76-.46,2.32-1.6,4.94v13.36Zm14.3-12.98h-5.44v4.3h4.7v1.54h-4.7v4.92h5.88v1.56h-13.52v-1.56h5.98v-4.92h-4.82v-1.54h4.82V5.96h-5.5v-1.54h12.6v1.54Zm-5.1-1.82c-1.18-.98-2.52-1.84-3.94-2.54l1-1.32c1.12,.48,2.56,1.26,3.94,2.44l-1,1.42Z" />
      <path d="M78.45,18.94c-3.6-1.08-5.5-2.42-6.5-3.18-2.78,2.22-5.5,2.92-6.6,3.2l-.8-1.6c1.32-.3,3.62-.92,6.14-2.72-2.02-2.08-2.8-4.1-3.1-4.9l1.48-.38c1.02,2.38,2.14,3.56,2.88,4.26,1.94-1.76,2.68-3.8,3-4.76h-7.74c-1.22,5.02-3.36,8.16-5.56,9.9l-1.28-1.32c3.24-2.58,5.26-7,5.96-12.78h-5.66v-1.58h5.82c.1-1.14,.1-1.88,.12-2.68h1.68c-.02,.68-.02,1.34-.14,2.68h10.94v1.58h-11.1c-.12,1.04-.28,1.88-.44,2.72h9.18v1.48c-.34,1.02-1.1,3.34-3.54,5.78,2.44,1.62,4.82,2.26,6.12,2.58l-.86,1.72Z" />
      <path d="M99.29,16.94l-.36,1.54h-8.56c-3.84,0-5.98-1.8-6.6-2.4-1.04,1.8-1.98,2.58-2.36,2.9l-.98-1.44c.6-.52,1.5-1.3,2.3-2.74-1.12-1.74-1.5-3.46-1.72-4.48l1.4-.32c.18,.96,.42,1.94,.98,3.24,.28-.88,.58-2.12,.74-4.3h-3.46v-1.64c.88-1,2.38-2.94,3.18-4.58h-3.24V1.22h5.04v1.44c-1,2.04-1.98,3.3-3.26,4.84h3.26c-.14,3.36-.72,5.88-1.26,7.26,.2,.22,2.16,2.18,6.22,2.18h8.68Zm-6.38-15.22h4.9v2.88h1.28v1.32h-1.28v2.88h-4.9v1.4h5.36v1.32h-5.36v1.56h6.1v1.36h-6.1v1.82h-1.56v-1.82h-5.44v-1.36h5.44v-1.56h-4.76v-1.32h4.76v-1.4h-4.12v-1.34h4.12v-1.54h-5.44v-1.32h5.44v-1.52h-4.12V1.72h4.12V.42h1.56V1.72Zm3.4,1.36h-3.4v1.52h3.4v-1.52Zm0,2.84h-3.4v1.54h3.4v-1.54Z" />
      <path d="M108.11,5.26h-7.52v-1.44h7.52v1.44Zm-.68-2.84h-6.2V1h6.2v1.42Zm0,5.66h-6.2v-1.44h6.2v1.44Zm0,2.84h-6.2v-1.44h6.2v1.44Zm0,1.44v5.7h-4.74v.9h-1.46v-6.6h6.2Zm-1.46,1.34h-3.28v2.98h3.28v-2.98Zm12.76,5.26c-1.42-.44-3.22-1.1-5.2-2.54-2.34,1.76-4.2,2.26-5.22,2.54l-.84-1.56c2.56-.6,3.86-1.38,4.78-2.02-1.74-1.6-2.38-3.06-2.74-3.86l1.36-.58c.34,.76,1.04,2.2,2.56,3.48,.96-.88,1.9-2.02,2.82-3.94h-7.62v-1.58h9.32v1.56c-.34,.88-1.1,2.86-3.2,4.9,1.88,1.18,3.64,1.72,4.72,2.02l-.74,1.58Zm-2.48-17.96V5.42c0,.2,.06,.52,.48,.52h.46c.28,0,.4-.12,.48-.44,.08-.3,.2-1.5,.24-2.18l1.5,.36c-.06,3.7-1.06,3.82-1.78,3.82h-1.6c-.3,0-1.34,0-1.34-1.28V2.54h-3.2c-.18,3.02-.74,4.24-2.34,5.52l-1.2-1.22c1.42-.9,2-2.58,2-4.94,0-.36,0-.6-.02-.9h6.32Z" />
      <path d="M121.59,8.64c3.26-1.88,5.24-4.74,6.32-7.8l2,.32c-.28,.7-.62,1.54-1.28,2.66h8.82c-2.04,12.24-10.72,13.86-14.02,14.48l-.78-1.66c4.5-.68,10.22-2.42,12.56-11.14h-7.66c-2.18,2.98-3.86,3.96-4.8,4.52l-1.16-1.38Zm13.62-5.34c-.4-1.28-.58-1.78-1.08-2.82l1.32-.34c.48,.9,.86,1.88,1.16,2.8l-1.4,.36Zm2.74-.16c-.12-.36-.5-1.56-1.14-2.8l1.34-.34c.46,.9,.88,1.78,1.2,2.74l-1.4,.4Z" />
      <path d="M145.85,7.1c0,2.2-.38,4.88-1.06,6.98-.78,2.38-2.26,3.78-2.96,4.44l-1.32-1.34c.84-.8,2.2-2.18,2.82-4.7,.28-1.12,.64-3.58,.64-5.12V1.72h1.88V7.1Zm5.36,8.78c3.68-.9,5.54-3.6,6.36-8.08l1.68,.68c-1.68,8.62-7.66,9.3-9.9,9.52V1.12h1.86V15.88Z" />
      <rect x="160.99" y="8.64" width="17.76" height="1.92" />
      <path d="M183.63,16.28c8.78-1.3,11.1-6.18,11.82-11.94h-13.6v-1.7h13.58c-.02-.1-.04-.2-.04-.36,0-1.16,.94-2.08,2.08-2.08s2.08,.92,2.08,2.08-.88,2.14-2.2,2.08c-.86,7.06-4,11.94-12.9,13.66l-.82-1.74Zm12.78-14c0,.58,.48,1.06,1.06,1.06s1.06-.48,1.06-1.06-.48-1.06-1.06-1.06-1.06,.46-1.06,1.06" />
    </svg>
  </SMCR>
);

export default Smcr;
