import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import useLocale from '../hooks/useLocale';
import useSiteMetadata from '../hooks/useSiteMetadata';
import { DataContainsNode, useTransformNode } from '../hooks/useTransformer';

type Props = {
  title?: string;
  description?: string;
  pathname?: string;
  article?: string;
  canonical?: string;
  product?: 'ricoh360' | 'tours' | 'projects';
};

const SEO_QUERY = graphql`
  query Seo {
    allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "seo" } } }) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            ogp {
              projects {
                ogImage {
                  publicURL
                }
                ogUrl
                ogDescription
              }
            }
          }
        }
      }
    }
  }
`;

const SEO: React.FC<Props> = ({
  title,
  description,
  pathname,
  article,
  canonical,
  product = 'projects',
}) => {
  const site = useSiteMetadata();
  const { language } = useLocale();
  const data = useStaticQuery<GatsbyTypes.SeoQuery>(
    SEO_QUERY,
  ) as DataContainsNode;
  const { frontmatter } = useTransformNode(data);
  const desc = description ?? frontmatter?.ogp?.[product]?.ogDescription;
  const url = frontmatter?.ogp?.projects?.ogUrl;
  const image = frontmatter?.ogp?.projects?.ogImage?.publicURL;

  const seo = {
    description: desc ? desc : site.description,
    title: title ? title : site.title,
    url: url ? url : `${site.siteUrl}${pathname ?? ''}`,
    image: image ? image : '',
    canonical: canonical ? canonical : '',
  };

  return (
    <Helmet
      defaultTitle={site.title ?? ''}
      titleTemplate={`%s | ${site.title}`}
    >
      {title && <title>{title}</title>}
      <meta charSet="utf-8" />
      <meta name="robots" content="index,follow" />
      <meta name="googlebot" content="index,follow" />
      <meta name="description" content={seo.description ?? ''} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {site.twitterUrl && (
        <meta name="twitter:creator" content={site.twitterUrl ?? ''} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      <link rel="apple-touch-icon" href="apple-touch-icon.png" />
      <html lang={language} dir="ltr" />
      {seo.canonical && <link rel="canonical" href={seo.canonical} />}
    </Helmet>
  );
};

export default SEO;
