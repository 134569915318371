import styled from 'styled-components';
import MDXLink from '@/components/MDXLink';

interface ButtonProps {
  buttonTheme?: string;
}

export const HeaderMain = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const HeaderLinkWrapper = styled.div`
  display: none;
  border-bottom: 1px solid #f2f2f2;
  @media (min-width: 1000px) {
    display: flex;
  }
`;
export const HeaderLinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 95%;
  max-width: 1297px;
  padding: 6px 0;
  margin: 0 auto;
`;

export const HeaderLink = styled.a`
  margin-left: 16px;
  font-size: 14px;
  line-height: 16px;
  color: #676767;
  text-decoration: underline;
  @media (max-width: 1000px) {
    display: block;
    margin: 24px 0px 0px;
    font-size: 16px;
    text-align: center;
  }
`;

export const HeaderWrapper = styled.header`
  top: 0;
  z-index: 99999;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  .sticky-outer-wrapper {
    width: 100%;
  }
  .sticky-active {
    width: 100%;
    .headerdiv {
      box-shadow: 0px 3px 6px #00000016;
    }
  }
  @media (min-width: 1000px) {
    height: 80px;
  }
`;

export const HeaderOuter = styled.div`
  position: relative;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
`;

export const HeaderInner = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 80px auto;
  grid-template-columns: auto 1fr;
  width: 95%;
  max-width: 1297px;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 1000px) {
    position: relative;
    box-sizing: border-box;
    grid-template-rows: 60px auto;
    grid-template-columns: 1fr auto;
    width: 100%;
    padding: 0 2.5%;
  }
`;

export const HeaderLeft = styled.nav`
  display: flex;
  align-items: center;

  @media (min-width: 1000px) {
    display: flex;
    width: auto;
    max-width: none;
    margin-right: 0;
    margin-left: 0;
  }
`;

export const HeaderChildrenList = styled.div`
  padding: 0 16px;
  @media (min-width: 1000px) {
    display: flex;
    height: 100%;
    padding: 0 8px;
  }
`;

export const NavLinkWrap = styled.div`
  position: relative;
  border-bottom: solid 1px #bebebe;

  &::before {
    position: absolute;
    top: 100%;
    left: -100vw;
    z-index: -1;
    width: 200vw;
    height: 100vh;
    pointer-events: none;
    background-color: #272727;
    opacity: 0.8;
  }

  > .nav-popup {
    display: none;
  }

  @media (max-width: 999.9px) {
    &.-detail-open {
      > button {
        &::before {
          width: 1px;
        }
      }

      > .nav-popup {
        display: block;
      }
    }
  }

  @media (min-width: 1000px) {
    border: none;

    &:hover,
    &.-now {
      color: #ea5851;
    }

    &.-has-child {
      &:hover {
        &::before {
          content: '';
        }

        > .nav-popup {
          display: flex;
          width: fit-content;
        }
      }
    }
  }
`;

export const NavLink = styled(MDXLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;
  font-size: 20px;
  color: #000;
  text-decoration: none;
  background: #fff;
  border: 0px;

  @media (min-width: 1000px) {
    height: 100%;
    padding: 0 12px;
    font-size: 16px;
  }

  &::before {
    position: absolute;
    right: 6px;
    bottom: 50%;
    width: 12px;
    height: 12px;
    content: '';
    border-top: solid 2.5px #ea5851;
    border-right: solid 2.5px #ea5851;
    transform: translateY(50%) rotate(45deg);

    @media (min-width: 1000px) {
      content: none;
    }
  }

  &.-has-children {
    &::before {
      content: none;
    }
  }
`;

export const NavLinkArrow = styled.svg`
  display: none;
  width: 10px;
  height: 10px;
  margin-bottom: 4px;
  margin-left: 6px;

  @media (min-width: 1000px) {
    display: block;
  }
`;

export const HeaderNavigationMenuContainer = styled.ul`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  padding-left: 0;
  margin: 0;
  list-style: none;
  @media (max-width: 1000px) {
    position: absolute;
    display: block;
    grid-row: 2;
    align-self: stretch;
    width: 100%;
    height: calc(100vh - 60px);
    max-height: 0px;
    overflow-y: scroll;
    background: white;
    &[data-expanded='true'] {
      max-height: 100vh;
    }
  }
`;

export const NavPopupButton = styled.button`
  position: absolute;
  top: 18px;
  right: 0;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 0px;

  &::before,
  &::after {
    position: absolute;
    right: 50%;
    bottom: 50%;
    width: 18px;
    height: 2px;
    content: '';
    background-color: #ea5851;
    border: none;
  }

  &::before {
    transform: translate(50%, 50%) rotate(90deg);
  }

  &::after {
    transform: translate(50%, 50%);
  }

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const NavPopup = styled.div`
  position: initial;
  top: 100%;
  right: 25px;
  color: initial;

  &::before,
  &::after {
    position: absolute;
    right: 50%;
    width: 12px;
    height: 12px;
    content: none;
    transform: translateX(50%) rotate(45deg);

    @media (min-width: 1000px) {
      content: '';
    }
  }

  &::before {
    top: -6px;
    background-color: #bebebe;
  }

  &::after {
    top: -5px;
    background-color: #fbf8f8;
  }

  @media (min-width: 1000px) {
    position: absolute;
    left: 50%;
    display: flex;
    padding: 16px 0;
    white-space: nowrap;
    background-color: #fbf8f8;
    border: solid 1px #bebebe;
    transform: translateX(-50%);
  }
`;

export const NavPopupName = styled.p`
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.5;
  color: #a8a8a8;

  @media (min-width: 1000px) {
    padding: 8px 32px;
    margin-bottom: 4px;
  }
`;

export const NavPopupList = styled.div`
  padding-bottom: 24px;

  @media (min-width: 1000px) {
    padding-bottom: 0;
  }

  &:nth-child(n + 2) {
    @media (min-width: 1000px) {
      position: relative;
    }

    &::before {
      position: absolute;
      top: 8px;
      bottom: 0;
      left: 0;
      width: 1px;
      background-color: #bebebe;

      @media (min-width: 1000px) {
        content: '';
      }
    }
  }
`;

export const NavPopupLink = styled(MDXLink)`
  display: flex;
  align-items: center;
  padding: 8px 0;
  line-height: 1.5;
  color: #000;
  text-decoration: none;

  @media (min-width: 1000px) {
    padding: 8px 32px;
  }
`;

export const NavPopupLinkArrow = styled.svg`
  margin-right: 5px;
  fill: ${({ theme }) =>
    theme === 'tours' ? '#EA5851' : theme === 'projects' ? '#0093bb' : '#333'};
`;

export const HeaderNavigationMenuLink = styled.a`
  position: relative;
  display: block;
  margin: 0;
  color: ${({ theme }) =>
    theme === 'tours' ? '#EA5851' : theme === 'projects' ? '#0093bb' : '#333'};
  text-decoration: none;
  word-break: keep-all;
  white-space: nowrap;
  :after {
    position: absolute;
    bottom: 0;
    left: 0%;
    width: 100%;
    height: 2px;
    content: '';
    background: ${({ theme }) =>
      theme === 'tours'
        ? '#EA5851'
        : theme === 'projects'
        ? '#0093bb'
        : '#333'};
    transition: all 0.3s;
    transform: scale(0, 1);
    transform-origin: center top;
  }

  :hover::after {
    transform: scale(1, 1);
  }
  @media (max-width: 1000px) {
    font-weight: bold;
  }
`;

export const HeaderNavigationMenuButton = styled.button<ButtonProps>`
  display: block;
  padding: 8px 16px;
  margin: 0;
  font-size: 16px;
  color: ${({ theme, buttonTheme }) =>
    buttonTheme === 'Primary'
      ? '#fff'
      : theme === 'tours'
      ? '#EA5851'
      : theme === 'projects'
      ? '#0093bb'
      : '#333'};
  text-decoration: none;
  word-break: keep-all;
  white-space: nowrap;
  cursor: pointer;
  background-color: ${({ theme, buttonTheme }) =>
    buttonTheme === 'Primary'
      ? theme === 'tours'
        ? '#EA5851'
        : theme === 'projects'
        ? '#0093bb'
        : '#333'
      : '#fff'};
  border: ${({ theme }) =>
    theme === 'tours'
      ? '1px solid #EA5851'
      : theme === 'projects'
      ? '1px solid #0093bb'
      : '1px solid #333'};
  border-radius: 9999px;
  @media (max-width: 1000px) {
    width: 95%;
    font-weight: bold;
    text-align: center;
  }
  :hover {
    color: ${({ theme, buttonTheme }) =>
      buttonTheme === 'Primary'
        ? theme === 'tours'
          ? '#fff'
          : theme === 'projects'
          ? '#0093bb'
          : '#333'
        : '#fff'};
    background-color: ${({ theme, buttonTheme }) =>
      buttonTheme === 'Primary' && theme === 'tours'
        ? '#D34C46'
        : buttonTheme === 'Primary' && theme === 'projects'
        ? '#fff'
        : theme === 'tours'
        ? '#EA5851'
        : theme === 'projects'
        ? '#0093bb'
        : '#333'};
  }
`;

export const HeaderNavigationItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 12px;
  :hover {
    cursor: default;
  }
  :hover > div {
    height: 100%;
    visibility: visible;
    opacity: 1;
  }
  @media (max-width: 1024px) {
    margin: 0 8px;
  }
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    margin: 0;
    > a,
    > button {
      box-sizing: border-box;
      width: 95%;
      padding: 16px;
      margin: 8px auto;
      font-weight: normal;
    }
  }
`;

export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  > svg {
    height: 30px;
  }
  @media (max-width: 1024px) {
    > svg {
      height: 24px;
    }
  }
`;

export const NavigationMenuToggle = styled.i`
  box-sizing: initial;
  display: none;
  align-self: center;
  padding: 0.6em 1em;
  cursor: pointer;
  border-radius: 3px;
  transition: box-shadow 0.3s ease, color 0.3s ease, background-color 0.3s ease;
  @media (max-width: 1000px) {
    display: block;
  }
`;

export const LogoPhoneContainer = styled.a`
  position: absolute;
  top: 50%;
  left: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  transform: transLateY(-50%);
  @media (max-width: 1024px) {
    left: 180px;
  }
  @media (max-width: 1000px) {
    right: 64px;
    left: auto;
  }
`;

export const ContactNumberDivNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  a {
    margin-left: 5px;
  }
`;

export const ContactNumberNavigation = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  color: #0093bb;
  text-transform: initial;
  letter-spacing: 0.03em;
  cursor: pointer;
  transition: none;
  @media (max-width: 640px) {
    display: none;
  }
`;
