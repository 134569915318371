import React from 'react';

const Ricoh360: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 376.04 100">
    <rect width="376.04" height="100" fill="none" />
    <path d="M.48,20.88c6.9.15,13.8.3,20.7.5a22.72,22.72,0,0,1,5.15.91c7.15,2,10.55,6.58,10.65,14.11.05,6.27-3.7,11.09-10.3,13.1L25.33,50c4.05,2.11,5.4,5.88,7.05,9.34,2.95,6.28,5.9,12.51,8.8,18.78a4.09,4.09,0,0,1,0,.81c-2.7,0-5.45.1-8.15-.1-.6,0-1.35-1.11-1.65-1.81-2.5-5.53-5.15-11-7.35-16.72-2-5.32-5.55-7.68-11.1-7.13-1.3.1-2.6,0-4.15,0V78.68l-8.35.45Zm8.25,7.23V46.44c4.85-.6,9.35-.7,13.6-1.76,4.05-1,6-4.37,5.75-8.33A7.4,7.4,0,0,0,21.43,29C17.43,28.36,13.23,28.41,8.73,28.11Z" />
    <path d="M177.33,48.9c-.25,9.39-2,17-7.75,23-8.25,8.43-18.45,9.59-29.15,6.62-10.35-2.91-16-10.59-17.8-20.88-1.75-9.59-.95-19,5.05-27.07,7.15-9.69,17.25-12,28.5-9.79s17.69,9.94,20.25,21C177,44.53,177.12,47.39,177.33,48.9Zm-8.8,1.31A27.12,27.12,0,0,0,165,36.05c-6.95-11.7-24.15-11.85-30.85,0a27.3,27.3,0,0,0-.11,27.81c6.55,11.9,24.2,11.75,31.05,0A27.35,27.35,0,0,0,168.53,50.21Z" />
    <path d="M225.28,52.62h-27.6V78.68h-8.11V21.13h8.05v24.1h5c6.75,0,13.5,0,20.26.05,1.85,0,2.44-.5,2.39-2.41-.1-6.27-.05-12.55-.05-18.83V21.13h8.05V78.68h-8V52.62Z" />
    <path d="M375.52,49.85c0,6.58-.7,13-3.45,19.08-3.15,6.93-8.35,10.9-16.15,10.9S342.82,76,340,69a49.92,49.92,0,0,1,0-38.11c2.81-7,8.31-10.79,16-10.84s12.9,3.86,16,10.74C374.73,36.85,375.52,43.28,375.52,49.85Zm-8.25.41-.39-.11a59.88,59.88,0,0,0-.55-7.23,61.92,61.92,0,0,0-2.7-10.34c-1.25-3.36-3.75-5.72-7.75-5.72s-6.05,2.41-7.61,5.57a21.15,21.15,0,0,0-1.25,3,56.68,56.68,0,0,0,0,29c1.5,5.62,4.85,8.63,9,8.63s7.5-2.91,8.86-8.53C366.08,59.9,366.52,55,367.27,50.26Z" />
    <path d="M325.52,29.07c-6.85-2.51-13.45-3.92-19.39,1.1-5.21,4.37-6.61,10.49-6.8,17.63,3.55-3.22,7.1-5,11.35-5.28,10.95-.7,18.45,6.48,18.7,17.78.3,14.11-12.05,23.1-25.35,18.43-5.6-2-8.9-6.18-10.45-11.65a51.41,51.41,0,0,1,.95-33c3.8-10.2,12.2-15.12,23.05-13.91a73.58,73.58,0,0,1,7.4,1.2c.6.15,1.5,1,1.5,1.46C326.27,24.9,325.88,26.91,325.52,29.07Zm-4.39,32.28c-.55-5.72-3.36-10.34-7.46-11.6a10.41,10.41,0,0,0-11.45,4.07c-3.05,4.62-2.5,11.9,1.25,16.12,4.1,4.57,11.3,4.37,14.6-.81C319.58,66.78,320.13,63.91,321.13,61.35Z" />
    <path d="M245.43,77.77c.19-2.76.39-5.07.6-7.33,3.2.85,6.25,1.81,9.4,2.41a19.1,19.1,0,0,0,11.9-1.61c3.35-1.6,5.29-4.16,5.25-8.08s-1.4-7.43-5.2-8.94c-3.05-1.15-6.4-1.45-9.65-2a31.19,31.19,0,0,0-4.35-.05V45.64c2.89-.2,5.7-.31,8.45-.66a19.17,19.17,0,0,0,4.75-1.35c4.1-1.76,5.8-4.62,5.4-8.89-.36-3.67-2.75-6.38-6.8-7.38-5.05-1.21-9.9-.35-14.65,1.46l-2.5,1c-.1-1.26-.2-2.31-.25-3.36-.3-4.12-.3-4.12,3.84-5.08,5.76-1.3,11.5-1.85,17.3-.5C278.92,23.24,284,34,279,42.22c-2.25,3.62-5.75,5.42-10.2,6.73l3.7,1.41c5.6,2.26,8.6,6.42,8.86,12.45s-2,11.2-7.65,14.06c-6.46,3.21-13.35,3.31-20.25,2.46A81.39,81.39,0,0,1,245.43,77.77Z" />
    <path d="M114.38,70.19c.1,1.41.15,2.81.3,4.22.3,3.61.35,3.81-3.15,4.42-11.2,2.1-22.15,1.7-31-6.63-9.6-9-11.1-20.54-7.45-32.59,3.6-11.8,12.5-17.67,24.4-19.28A34.56,34.56,0,0,1,113,21.79c1.3.45,2,.95,1.7,2.46-.3,1.95-.35,4-.5,6-13.1-4.82-22.75-3.11-29.15,4.92-5.85,7.23-6.5,19.18-1.45,27.37C89.28,71.9,97.78,74,114.38,70.19Z" />
    <path d="M59.48,78.78H51.63V21.13h7.85Z" />
  </svg>
);

export default Ricoh360;
