import React from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import Logo from '@/components/Logo';
import MDXLink from '@/components/MDXLink';
import { AppStore, GooglePlay } from '@/components/Svg';
import usePageContext from '@/hooks/usePageContext';
import useLocale from '../../hooks/useLocale';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';
// import { RoundOutlineButton } from './Button';
import {
  ActiveText,
  AuthBox,
  Box,
  ContainerBox,
  ContentBox,
  CopyrightText,
  FooterBox,
  ListBase,
  LogoLink,
  MenuBoxList,
  MenuTitle,
  RoundOutlineButton,
  Separator,
  StoreButton,
  StoreSvg,
  TextLink,
  TextLinkWithDecoration,
  TopLogoLink,
} from './Footer.style';

interface MenuItem {
  text: string;
  link: string;
  dataCy?: string;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}
interface Menu {
  title: string;
  items: MenuItem[];
}

type Props = {
  promotionalPage?: 'gen' | 'remax' | 'immovlan';
  languages: string[];
  data: DataContainsNode;
};

export const BaseFooter: React.FC<Props> = ({
  data,
  promotionalPage,
  languages,
}) => {
  const location = useLocation();
  const { theme } = usePageContext();
  const { frontmatter } = useTransformNode(data);
  const { language } = useLocale();
  const [currentLanguage, setCurrentLanguage] = React.useState(language);
  const langMapping: {
    [key: string]: string;
  } = data.allMarkdownRemark.edges.reduce(
    (o, { node }) =>
      node.fields?.language && node.frontmatter?.lang?.text
        ? { ...o, [node.fields.language]: node.frontmatter.lang.text }
        : o,
    {},
  );
  const menuItems = frontmatter?.contents?.map((content) => {
    const menuItem: Menu = {
      title: (content?.title || '').toUpperCase(),
      items: (content?.items?.filter(Boolean) || []) as Menu['items'],
    };
    return menuItem;
  });
  const menuLangItem: Menu[] = [
    {
      title: (frontmatter?.lang?.title || 'Language').toUpperCase(),
      items: languages.map((key) => ({
        text: langMapping[key],
        link:
          location.origin +
          (key === 'en' ? '' : `/${key}`) +
          location.pathname.replace(
            new RegExp(`^\\/(${languages.join('|')})\\/`),
            '/',
          ),
        active: language === key,
        dataCy: 'footerLanguageMenu',
        onClick: (event) => {
          event.preventDefault();
          setCurrentLanguage(key);
        },
      })),
    },
  ];
  const menuList = menuItems && menuItems.concat(menuLangItem);
  React.useEffect(() => {
    if (language !== currentLanguage) {
      // TODO: Need refactoring
      document.cookie = `nf_lang=${currentLanguage}; domain=${process.env.GATSBY_COOKIE_DOMAIN}; path=/; max-age=31536000`;
      document.cookie = `locale=${currentLanguage}; domain=${process.env.GATSBY_COOKIE_DOMAIN}; path=/; max-age=31536000`;
      // TODO
      if (window.location.pathname.match(/^\/404(\.[^.]+)?\/*$/)) return;
      const currentUrl = window.location.pathname.split('/');
      let newUrl = currentLanguage === 'en' ? '' : `/${currentLanguage}`;
      if (currentUrl[0] === '') {
        if (languages.includes(currentUrl[1])) {
          currentUrl.map((url, index) => {
            if (index > 1) {
              newUrl += `/${url}`;
            }
          });
        } else {
          currentUrl.map((url, index) => {
            if (index >= 1) {
              newUrl += `/${url}`;
            }
          });
        }
        navigate(newUrl);
        newUrl = '';
      }
    }
  }, [currentLanguage, language, languages]);

  return (
    <FooterBox as="footer">
      <LogoLink
        href={
          promotionalPage
            ? promotionalPage !== 'immovlan'
              ? `/tours/${promotionalPage}/sc2`
              : `/tours/${promotionalPage}`
            : frontmatter?.topLink
        }
        data-cy={theme ? `${theme}-footer-logo` : 'footer-logo'}
        css={`
          margin-bottom: 38px;
          @media (max-width: 768px) {
            margin-bottom: 48px;
          }
        `}
      >
        {theme ? <Logo name={theme} /> : <Logo name="others" />}
      </LogoLink>
      <ContainerBox>
        <MenuBoxList as="ul">
          {menuList &&
            menuList.map(({ title, items }, i) => (
              <Box as="li" key={i}>
                <MenuTitle>{title}</MenuTitle>
                <ListBase css="margin-top: 10px;">
                  {items?.map(({ text, link, active, dataCy, ...other }, j) => (
                    <Box as="li" key={`${i}-${j}`}>
                      {active ? (
                        <ActiveText
                          {...other}
                          data-cy={
                            theme
                              ? `${theme}-footer-menu-active`
                              : `footer-menu-active`
                          }
                        >
                          {text}
                        </ActiveText>
                      ) : (
                        <TextLink
                          data-cy={
                            dataCy
                              ? dataCy
                              : theme
                              ? `${theme}-footer-menu-${i}-${j}`
                              : `footer-menu-${i}-${j}`
                          }
                          href={link}
                          {...other}
                        >
                          {text}
                        </TextLink>
                      )}
                    </Box>
                  ))}
                </ListBase>
              </Box>
            ))}
        </MenuBoxList>
        <AuthBox
          css={`
            margin-left: auto;
            @media (max-width: 768px) {
              margin: 0;
              margin-top: 48px;
            }
          `}
        >
          {frontmatter?.auth?.signup?.link && frontmatter.auth.signup.text && (
            <RoundOutlineButton
              as={MDXLink}
              href={frontmatter.auth.signup.link}
              css={`
                margin-top: 11px;
                @media (max-width: 768px) {
                  margin-top: 18px;
                }
              `}
              data-cy={
                theme ? `${theme}-footer-auth-signup` : 'footer-auth-signup'
              }
            >
              {frontmatter.auth.signup.text}
            </RoundOutlineButton>
          )}
          {frontmatter?.auth?.signin?.link && frontmatter.auth.signin.text && (
            <RoundOutlineButton
              as={MDXLink}
              href={frontmatter.auth.signin.link}
              css={`
                margin-top: 8px;
                @media (max-width: 768px) {
                  margin-top: 18px;
                }
              `}
              data-cy={
                theme ? `${theme}-footer-auth-login` : 'footer-auth-login'
              }
            >
              {frontmatter.auth.signin.text}
            </RoundOutlineButton>
          )}
        </AuthBox>
      </ContainerBox>
      <Separator
        css={`
          margin: 24px 0;
          @media (max-width: 768px) {
            margin-top: 36px;
            margin-bottom: 24px;
          }
        `}
      />
      <ContainerBox>
        <ContentBox
          orderOnMobile={0}
          marginTopOnMobile={0}
          css={`
            @media (max-width: 768px) {
              flex-direction: column;
            }
            ${TextLinkWithDecoration}:not(:first-of-type) {
              margin-left: 24px;
              @media (max-width: 768px) {
                margin-left: 0;
                margin-top: 13px;
              }
            }
          `}
        >
          <TopLogoLink href="https://www.ricoh360.com/">
            <Logo name="others" />
          </TopLogoLink>
          {frontmatter?.tos?.filter(Boolean).map(
            (item, i) =>
              item &&
              item.link &&
              item.text && (
                <TextLinkWithDecoration
                  key={i}
                  href={item.link}
                  data-cy={`${theme}-footer-tos-${i}`}
                >
                  {item.text}
                </TextLinkWithDecoration>
              ),
          )}
        </ContentBox>
        <ContentBox
          orderOnMobile={2}
          marginTopOnMobile={40}
          css={`
            margin-left: 36px;
            @media (max-width: 1000px) {
              margin-left: 0;
            }
          `}
        ></ContentBox>
        <ContentBox
          orderOnMobile={1}
          marginTopOnMobile={46}
          css={`
            margin: 0 auto;
            @media (max-width: 768px) {
              margin-top: 30px;
            }
          `}
        >
          {frontmatter?.storeLink ? (
            <>
              {[
                {
                  SvgComp: AppStore,
                  link: `${frontmatter?.storeLink?.appStore}?l=${language}`,
                },
                {
                  SvgComp: GooglePlay,
                  link: `${frontmatter?.storeLink?.googlePlay}&hl=${language}`,
                },
              ].map(({ SvgComp, link }, i) => (
                <StoreButton
                  key={link}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-cy={
                    theme
                      ? `${theme}-footer-appstore-${i}`
                      : `footer-appstore-${i}`
                  }
                >
                  <StoreSvg as={SvgComp} locale={language} />
                </StoreButton>
              ))}
            </>
          ) : null}
        </ContentBox>
        <ContentBox
          orderOnMobile={3}
          marginTopOnMobile={46}
          css={`
            @media (max-width: 768px) {
              flex-direction: column-reverse;
            }
          `}
        >
          <CopyrightText
            css={`
              margin-right: 0px;
              @media (max-width: 768px) {
                margin-top: 12px;
              }
            `}
          >
            &copy; Ricoh Co., Ltd.
          </CopyrightText>
        </ContentBox>
      </ContainerBox>
    </FooterBox>
  );
};
