import styled from 'styled-components';
import MDXLink from '../MDXLink';

export const TitleWrapper = styled.div`
  body & {
    margin-bottom: 20px;
    text-align: center;
  }
`;

export const MainTitle = styled.h2`
  body & {
    font-size: 28px;
    font-weight: 300;
    line-height: 1.4;
    text-align: center;
    letter-spacing: 1px;

    /* PC */
    @media (min-width: 1024px) {
      font-size: 36px;
    }
  }
`;

export const TitleRead = styled.p`
  body & {
    font-size: 14px;
    text-align: center;
  }
`;

export const CommonAButton = styled(MDXLink)`
  body & {
    display: block;
    width: 80%;
    margin: 0 auto;
    line-height: 60px;
    color: #0093bb;
    text-align: center;
    text-decoration: none;
    background: #fff;
    border-radius: 30px;

    /* TAB */
    @media (min-width: 768px) {
      font-size: 20px;
    }
  }
`;
