import React from 'react';

const HibiyaEng: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156.58 14" height={20}>
    <path
      style={{ fill: '#013b80' }}
      d="M4.11,12.45v-.74a.32.32,0,0,1,.24-.34.31.31,0,0,1,.4.29c0,.39,0,.77,0,1.16,0,.08,0,.1.1.1h.8a.26.26,0,0,0,.28-.21.27.27,0,0,0-.28-.33.31.31,0,0,1-.26-.47.29.29,0,0,1,.25-.17.28.28,0,0,0,.29-.21.28.28,0,0,0-.28-.34H5.23a.32.32,0,0,1,0-.63,2.64,2.64,0,0,1,.63,0A.9.9,0,0,1,6.41,12a.08.08,0,0,0,0,.12.91.91,0,0,1-.6,1.43H4.48a.34.34,0,0,1-.37-.38Z"
    />
    <path
      style={{ fill: '#013b80' }}
      d="M10.14,13.24a.61.61,0,0,1,0-.13c.31-.77.63-1.53.95-2.3a.32.32,0,0,1,.21-.2.3.3,0,0,1,.39.19l.57,1.37.39.95a.31.31,0,0,1-.1.38.33.33,0,0,1-.38,0,.66.66,0,0,1-.12-.17c-.06-.12-.11-.26-.16-.38a.09.09,0,0,0-.08-.05H11s-.06,0-.08.07-.1.26-.16.39a.31.31,0,0,1-.38.19A.31.31,0,0,1,10.14,13.24Zm1.29-1.48h0l-.25.63h.52Z"
    />
    <path
      style={{ fill: '#013b80' }}
      d="M.65,12s0,.06,0,.08V13.2a.36.36,0,0,1-.11.29.34.34,0,0,1-.35,0A.29.29,0,0,1,0,13.24V11.67a.31.31,0,0,1,.33-.33H2.14a.31.31,0,0,1,.33.33v1c0,.19,0,.38,0,.57a.32.32,0,0,1-.62.07.68.68,0,0,1,0-.14c0-.38,0-.76,0-1.14,0-.06,0-.08-.08-.08H.65Z"
    />
    <path
      style={{ fill: '#013b80' }}
      d="M9.3,11.93c.14-.16.27-.3.39-.45a.27.27,0,0,1,.27-.13h.32c.05,0,.11,0,.14.06s0,.11,0,.15l-.71.91a.26.26,0,0,0,0,.15c0,.19,0,.39,0,.59a.31.31,0,0,1-.31.35A.32.32,0,0,1,9,13.22v-.6a.31.31,0,0,0-.05-.17l-.7-.9a.14.14,0,0,1,0-.14s.08-.05.12-.05h.41a.16.16,0,0,1,.13.06l.39.45Z"
    />
    <path
      style={{ fill: '#013b80' }}
      d="M3,12.05V10.89a.33.33,0,1,1,.65,0v2.35a.33.33,0,1,1-.65,0Z"
    />
    <path
      style={{ fill: '#013b80' }}
      d="M7.73,12.06c0,.39,0,.78,0,1.17a.31.31,0,0,1-.36.33.32.32,0,0,1-.28-.33V10.87a.33.33,0,0,1,.24-.31.32.32,0,0,1,.36.16.39.39,0,0,1,0,.18C7.73,11.29,7.73,11.67,7.73,12.06Z"
    />
    <path
      style={{ fill: '#013b80' }}
      d="M8.06,10.87a.31.31,0,0,1,.32-.31.32.32,0,1,1,0,.64A.31.31,0,0,1,8.06,10.87Z"
    />
    <path
      style={{ fill: '#179354' }}
      d="M9.91,10.87a.3.3,0,0,1,.32-.31.31.31,0,0,1,.31.32.32.32,0,0,1-.32.32A.3.3,0,0,1,9.91,10.87Z"
    />
    <path
      style={{ fill: '#013b80' }}
      d="M.32,10.56a.32.32,0,1,1,0,.64A.33.33,0,0,1,0,10.86.31.31,0,0,1,.32,10.56Z"
    />
    <path
      style={{ fill: '#179354' }}
      d="M2.15,10.56a.32.32,0,1,1,0,.64.33.33,0,0,1-.32-.34A.31.31,0,0,1,2.15,10.56Z"
    />
    <path
      style={{ fill: '#179354' }}
      d="M4.43,10.56a.32.32,0,0,1,.32.32.32.32,0,0,1-.32.32.33.33,0,0,1-.32-.34A.31.31,0,0,1,4.43,10.56Z"
    />
    <path style={{ fill: '#fff' }} d="M11.43,11.76l.25.62h-.52l.25-.63Z" />
    <path
      style={{ fill: '#013b80' }}
      d="M11.92,4A9.6,9.6,0,0,0,2.07,6.23l0,0,.16-.12a9.55,9.55,0,0,1,6.93-2.4,9.55,9.55,0,0,1,2.7.56.32.32,0,0,1,.24.25l.1.3a9.51,9.51,0,0,0-7.35-.06A9.31,9.31,0,0,0,1.49,7.15l0,0a.31.31,0,0,0,.09-.07A9.52,9.52,0,0,1,6.3,4.57a9.41,9.41,0,0,1,3-.17,9.81,9.81,0,0,1,2.95.77.27.27,0,0,1,.17.19c0,.21.09.41.14.63A9.52,9.52,0,0,0,5.67,5.2,9.79,9.79,0,0,0,0,9.16c.14-.32.26-.64.42-1A9.51,9.51,0,0,1,5.56,3.48,9.21,9.21,0,0,1,8,2.89a9.75,9.75,0,0,1,3.32.21.34.34,0,0,1,.25.2C11.67,3.55,11.79,3.79,11.92,4Z"
    />
    <path
      style={{ fill: '#013b80' }}
      d="M2.64,4.85V0h2a.53.53,0,0,1,0,.12V3.39c0,.08,0,.14-.08.18a9.25,9.25,0,0,0-1.82,1.2Z"
    />
    <path
      style={{ fill: '#013b80' }}
      d="M4.64,5.9V9.34h-2c0-.05,0-.09,0-.14,0-.7,0-1.4,0-2.1a.28.28,0,0,1,.07-.17,8.8,8.8,0,0,1,1.88-1Z"
    />
    <path
      style={{ fill: '#179354' }}
      d="M8.33,0h2.06V2.62a8.24,8.24,0,0,0-2.06-.06Z"
    />
    <path
      style={{ fill: '#179354' }}
      d="M8.33,5.22V9.36h2.05V5.51A8.51,8.51,0,0,0,8.33,5.22Z"
    />
    <path d="M17.31,2.19h1.86V5.62h3.55V2.2h1.84v9H22.72v-4H19.19v4H17.31Z" />
    <path d="M128.25,5.48V4.59H130v6.58a2.47,2.47,0,0,1-2.29,2.73,4.7,4.7,0,0,1-3.08-.4,1.74,1.74,0,0,1-.89-1.5h1.72a.25.25,0,0,1,.18.12c.32.63.9.6,1.46.54a1.09,1.09,0,0,0,1-.89c.09-.47.12-.94.17-1.42a5.57,5.57,0,0,1-1,.73,2.63,2.63,0,0,1-3.49-1.48,4.25,4.25,0,0,1,.5-4.3A2.45,2.45,0,0,1,128.25,5.48Zm0,2.41.09,0a11.34,11.34,0,0,0-.4-1.29,1.25,1.25,0,0,0-1.3-.66,1.17,1.17,0,0,0-1.09.76,3,3,0,0,0,0,2.34,1.33,1.33,0,0,0,1.9.64A1.8,1.8,0,0,0,128.24,7.89Z" />
    <path d="M60.66,5.71H65V7.3H60.66V9.62h5v1.61H58.82v-9h6.6V3.76H60.66Z" />
    <path d="M80.8,4.59v.62c0,2,0,4,0,6a2.45,2.45,0,0,1-.87,2.14,3.75,3.75,0,0,1-1.33.57,4.45,4.45,0,0,1-3.14-.36,1.76,1.76,0,0,1-1-1.51h1.74a.31.31,0,0,1,.2.14c.33.6.91.55,1.45.52a1.12,1.12,0,0,0,1-.86c.09-.47.12-1,.18-1.45a6.4,6.4,0,0,1-1,.73,2.64,2.64,0,0,1-3.39-1.26A4.55,4.55,0,0,1,75.4,5c.88-.84,2.81-1,3.57.27,0,0,.06.06.13.12V4.59ZM79.1,7.87a2.17,2.17,0,0,0-.4-1.42,1.29,1.29,0,0,0-1.3-.53,1.2,1.2,0,0,0-1.05.85,3.25,3.25,0,0,0,0,2.09,1.36,1.36,0,0,0,2.06.73A2,2,0,0,0,79.1,7.87Z" />
    <path d="M153.15,11.23h-1.67v-.9l-.32.39a2.5,2.5,0,0,1-3.37.16,3.49,3.49,0,0,1-1.16-2.51,4.87,4.87,0,0,1,.52-2.59,2.55,2.55,0,0,1,4.24-.43V2.23h1.76Zm-4.79-3.18a10,10,0,0,0,.33,1.2,1.19,1.19,0,0,0,1.14.74,1.3,1.3,0,0,0,1.27-.66,2.83,2.83,0,0,0,.18-2.39,1.35,1.35,0,0,0-1.41-1A1.33,1.33,0,0,0,148.55,7,9,9,0,0,0,148.36,8.05Z" />
    <path d="M31.47,10.47v.75H29.76v-9h1.7V5.39c.23-.2.4-.37.6-.52a2.54,2.54,0,0,1,3.59.67,4.28,4.28,0,0,1-.12,4.83,2.47,2.47,0,0,1-4,.15Zm-.06-2.61a2.79,2.79,0,0,0,.38,1.45,1.38,1.38,0,0,0,2.33.06,3,3,0,0,0,.12-2.67,1.42,1.42,0,0,0-2.63.16C31.52,7.19,31.48,7.53,31.41,7.86Z" />
    <path d="M106.87,8.38H102a1.58,1.58,0,0,0,.71,1.43,1.67,1.67,0,0,0,2.2-.34c0-.07.13-.16.2-.17.55,0,1.09,0,1.67,0a2.34,2.34,0,0,1-1.12,1.62,3.91,3.91,0,0,1-4.51-.36,2.93,2.93,0,0,1-.86-1.71,5.41,5.41,0,0,1,.08-2.12,2.87,2.87,0,0,1,2-2.18,3.6,3.6,0,0,1,3.17.36,2.9,2.9,0,0,1,1.29,2A12.78,12.78,0,0,1,106.87,8.38ZM105,7.23a1.43,1.43,0,0,0-1.67-1.42,1.37,1.37,0,0,0-1.27,1.42Z" />
    <path d="M99.77,8.39H94.94a1.59,1.59,0,0,0,1.89,1.66,1.41,1.41,0,0,0,1-.6.37.37,0,0,1,.24-.15h1.69a4.72,4.72,0,0,1-.44.9,3.52,3.52,0,0,1-4.17,1c-1.69-.66-2-1.89-2-3.47A3.33,3.33,0,0,1,94.45,5c1.28-1,4-.88,4.92.91A4.61,4.61,0,0,1,99.77,8.39ZM95,7.21h3a1.49,1.49,0,0,0-3,0Z" />
    <path d="M53.89,11.24H52l-.17-.71c-.25.19-.48.37-.72.53a2.49,2.49,0,0,1-2,.34,2,2,0,0,1-1.34-2.8,2.33,2.33,0,0,1,2-1.28c.53-.1,1.06-.18,1.59-.29a.52.52,0,0,0,.45-.59.54.54,0,0,0-.47-.55,4.45,4.45,0,0,0-1-.08c-.52,0-.76.41-.87.88H47.85a2.21,2.21,0,0,1,2.07-2.25,4.67,4.67,0,0,1,2.53.29,1.6,1.6,0,0,1,1.08,1.62c0,1.23,0,2.45,0,3.68,0,.35,0,.72.31,1C53.89,11,53.88,11.14,53.89,11.24ZM51.8,8.06c-.46.1-.88.19-1.3.3a4.92,4.92,0,0,0-.65.21.8.8,0,0,0-.46.88.76.76,0,0,0,.53.69,1.66,1.66,0,0,0,1.66-.57A2.28,2.28,0,0,0,51.8,8.06Z" />
    <path d="M47,4.54c-.28.82-.54,1.6-.81,2.37-.58,1.65-1.16,3.29-1.75,4.94-.14.39-.32.78-.48,1.17a1.47,1.47,0,0,1-1.33.92c-.36,0-.72,0-1.07,0s-.29-.07-.28-.28c0-.37,0-.73,0-1.15h.52a.93.93,0,0,0,.91-1.29L40.4,4.72a.63.63,0,0,1,0-.13h1.92l1.43,4.77h.07c.15-.51.3-1,.44-1.54.28-1,.56-2,.85-3a.4.4,0,0,1,.23-.23C45.86,4.53,46.41,4.54,47,4.54Z" />
    <path d="M68.79,11.23H67.07V4.59h1.64v.93L69,5.2a2.5,2.5,0,0,1,3.67,0,2.28,2.28,0,0,1,.44,1.49c0,1.51,0,3,0,4.56H71.3V7.34c0-.13,0-.25,0-.38-.07-.68-.37-1-.91-1.1a1.21,1.21,0,0,0-1.46.94,4.34,4.34,0,0,0-.13.94c0,1,0,2.07,0,3.1Z" />
    <path d="M122.2,11.22h-1.75v-.36c0-1.19,0-2.39,0-3.58a3.18,3.18,0,0,0,0-.62,1,1,0,0,0-.91-.8,1.2,1.2,0,0,0-1.38.76,4,4,0,0,0-.17,1c0,1.07,0,2.14,0,3.2v.4h-1.72V4.58h1.64v.86a5.71,5.71,0,0,1,.88-.77,2.64,2.64,0,0,1,2.87.28,1.72,1.72,0,0,1,.59,1.28c0,1.64,0,3.28,0,4.91C122.24,11.16,122.22,11.18,122.2,11.22Z" />
    <path d="M86,4.58h1.64v.89a2.57,2.57,0,0,0,.21-.19,2.38,2.38,0,0,1,2.8-.74A1.86,1.86,0,0,1,92,6.41c0,1.53,0,3.07,0,4.6a1.65,1.65,0,0,1,0,.21H90.29c0-.13,0-.26,0-.39,0-1.3,0-2.59,0-3.89a1,1,0,0,0-.87-1.07,1.23,1.23,0,0,0-1.48.81,4.71,4.71,0,0,0-.14,1.12c0,1.13,0,2.27,0,3.43H86Z" />
    <path d="M141.68,9.61v1.61h-6.39v-9h1.86V9.61Z" />
    <path d="M145.93,5.87h-1.09V9.43c0,.47.09.56.55.58l.51,0v1.27a5.34,5.34,0,0,1-2-.11,1,1,0,0,1-.76-1.09V5.88h-.93V4.62h.89V2.79h1.74V4.61h.86c.18,0,.27,0,.26.25S145.93,5.5,145.93,5.87Z" />
    <path d="M109.49,11.24h-1.73V4.59h1.62v1a2.28,2.28,0,0,1,2.21-1.16V6.15c-.25,0-.48,0-.7,0a1.31,1.31,0,0,0-1.36,1.35c0,1.08,0,2.16,0,3.23Z" />
    <path d="M114.46,11.23h-1.73V4.57h1.73Z" />
    <path d="M37.57,4.54c.52,0,1,0,1.55,0,.07,0,.19.17.19.27,0,.74,0,1.49,0,2.23v4.15H37.57Z" />
    <path d="M28.08,11.23H26.34V4.57h1.74Z" />
    <path d="M84.23,11.23H82.52V4.57h1.71Z" />
    <path d="M131.55,9.42h1.87a9.46,9.46,0,0,1-.16,2.61,2.08,2.08,0,0,1-1.7,1.35v-.66a1.39,1.39,0,0,0,1-1.48h-1Z" />
    <path d="M156.58,9.43v1.79h-1.81V9.43Z" />
    <path d="M26.32,3.71V2.15h1.75V3.71Z" />
    <path d="M112.73,2.14c.53,0,1,0,1.56,0,.07,0,.18.13.18.2,0,.45,0,.89,0,1.36h-1.75Z" />
    <path d="M39.32,3.73H37.57V2.14c.53,0,1,0,1.55,0,.07,0,.18.13.19.2C39.32,2.8,39.32,3.24,39.32,3.73Z" />
    <path d="M84.23,2.16V3.72H82.52V2.16Z" />
    <path style={{ fill: '#fff' }} d="M11.2,12.29l.26-.69.3.66Z" />
  </svg>
);

export default HibiyaEng;
