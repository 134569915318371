import React from 'react';

const ShimizuEng: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156.52 45" height={45}>
    <path
      style={{ fill: '#005eac' }}
      d="M155.72,6.82c0,3.76-4.56,6.81-10.18,6.81s-10.17-3.05-10.17-6.81S139.93,0,145.54,0,155.72,3.05,155.72,6.82Zm-10-5c-3.85-.13-7.05,2-7.15,4.76s3,5.11,6.8,5.25,7-2,7.15-4.76S149.57,2,145.72,1.81ZM142,9.58a1.48,1.48,0,0,0,1.56.52,10.33,10.33,0,0,0,3-1.86,10.1,10.1,0,0,0,2.54-2.46,1.48,1.48,0,0,0-1.68-2.15,10.23,10.23,0,0,0-3,1.86A10.28,10.28,0,0,0,141.86,8,1.49,1.49,0,0,0,142,9.58Z"
    />
    <polygon
      style={{ fill: '#005eac' }}
      points="75.88 15.51 75.88 45 86.89 45 86.89 24.56 91.59 24.56 91.59 45 102.6 45 102.6 24.56 107.29 24.56 107.29 45 118.32 45 118.32 15.51 75.88 15.51"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M59.79,15.51H72v7.87H59.79ZM48.52,25.86V15.51H37.38V45H48.52V34H60.34V45H71.48V25.86Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M30.47,31l-16.28-7.2c-.51-.26-.47-.82.14-.82H33.9V15.51H9.29C5,15.51.57,15.69.57,19.37v3.9C.57,26.92,3.9,28,5.63,29l15.42,7.12c.75.34.72,1.35-.15,1.35H0V45H25.37c6,0,9.08-1.09,9.08-4.84v-3.9C34.45,33.55,32.61,32.13,30.47,31Z"
    />
    <polygon
      style={{ fill: '#005eac' }}
      points="137.05 37.49 156.08 21.87 156.11 21.87 156.11 15.51 121.57 15.51 121.57 23.02 139.13 23.02 121.5 37.49 121.5 45 156.52 45 156.52 37.49 137.05 37.49"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M34.3,4.17c.81,0,2.44.14,2.58,1.59h-1.2c0-.38-.41-.8-1.33-.8-.74,0-1.23.21-1.23.69,0,1.17,3.94,0,3.94,2.18,0,1.3-1.41,1.63-2.54,1.63-1.9,0-2.67-.71-2.68-1.76H33c0,.66.61,1,1.52,1,.67,0,1.3-.19,1.3-.74C35.86,6.7,32,7.78,32,5.72,32,4.61,33,4.17,34.3,4.17Z"
    />
    <polygon
      style={{ fill: '#005eac' }}
      points="37.81 4.31 38.92 4.31 38.92 6.26 42.2 6.26 42.2 4.31 43.31 4.31 43.31 9.32 42.2 9.32 42.2 7.05 38.92 7.05 38.92 9.32 37.81 9.32 37.81 4.31"
    />
    <rect
      style={{ fill: '#005eac' }}
      x="44.46"
      y="4.31"
      width="1.11"
      height="5.01"
    />
    <polygon
      style={{ fill: '#005eac' }}
      points="46.73 4.31 48.32 4.31 50.17 8.2 52.01 4.31 53.59 4.31 53.59 9.32 52.54 9.32 52.59 5.18 52.58 5.18 50.62 9.32 49.67 9.32 47.74 5.18 47.72 5.18 47.77 9.32 46.73 9.32 46.73 4.31"
    />
    <rect
      style={{ fill: '#005eac' }}
      x="54.74"
      y="4.31"
      width="1.11"
      height="5.01"
    />
    <polygon
      style={{ fill: '#005eac' }}
      points="56.58 8.56 60.22 5.1 56.83 5.1 56.83 4.31 61.68 4.31 61.68 5.08 58.08 8.49 61.7 8.49 61.7 9.32 56.58 9.32 56.58 8.56"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M62.59,4.31h1.12V7.14c0,.69,0,1.54,1.47,1.54s1.48-.85,1.48-1.54V4.31h1.11V7.17c0,.83,0,2.29-2.59,2.29S62.59,8,62.59,7.17Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M75.38,6.17A1.53,1.53,0,0,0,73.67,5a1.73,1.73,0,0,0-1.78,2,1.74,1.74,0,0,0,1.86,1.77,1.5,1.5,0,0,0,1.63-1.27h1.2c-.15,1-.84,2.05-3,2.05a2.56,2.56,0,0,1-2.82-2.64c0-1.41.87-2.65,3-2.65,1.82,0,2.77.91,2.89,2Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M77,6.82c0-1.41.87-2.65,3.11-2.65s3.11,1.24,3.11,2.65-.88,2.64-3.11,2.64S77,8.23,77,6.82Zm5.06,0a2,2,0,0,0-3.91,0,2,2,0,0,0,3.91,0Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M83.73,4.31h3.35c1.35,0,1.78.59,1.78,1.36A1.19,1.19,0,0,1,88,6.88v0c.63.12.77.64.77,1.35A2,2,0,0,0,89,9.32h-1.2a1.62,1.62,0,0,1-.13-.75c0-.72-.1-1.23-.92-1.23H84.84v2H83.73Zm3,2.25c.61,0,.95-.18.95-.74s-.37-.72-.95-.72H84.84V6.56Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M89.62,4.31h3.25c1.3,0,1.72.75,1.72,1.56a1.53,1.53,0,0,1-1.7,1.54H90.73V9.32H89.62Zm1.11,2.32h1.78c.63,0,1-.17,1-.76S93,5.1,92.59,5.1H90.73Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M95,6.82c0-1.41.87-2.65,3.11-2.65s3.11,1.24,3.11,2.65-.88,2.64-3.11,2.64S95,8.23,95,6.82Zm5.07,0a2,2,0,0,0-3.91,0,2,2,0,0,0,3.91,0Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M101.71,4.31h3.34c1.34,0,1.78.59,1.78,1.36A1.18,1.18,0,0,1,106,6.88v0c.63.12.77.64.77,1.35A2,2,0,0,0,107,9.32h-1.2a1.62,1.62,0,0,1-.13-.75c0-.72-.1-1.23-.92-1.23h-1.88v2h-1.11Zm3,2.25c.62,0,1-.18,1-.74s-.36-.72-1-.72h-1.91V6.56Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M109.7,4.31H111l2.35,5h-1.23l-.55-1.24H109.1l-.55,1.24h-1.2Zm1.55,3-.9-2.11-.92,2.11Z"
    />
    <polygon
      style={{ fill: '#005eac' }}
      points="114.75 5.1 112.77 5.1 112.77 4.31 117.85 4.31 117.85 5.1 115.87 5.1 115.87 9.32 114.75 9.32 114.75 5.1"
    />
    <rect
      style={{ fill: '#005eac' }}
      x="118.56"
      y="4.31"
      width="1.11"
      height="5.01"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M120.45,6.82c0-1.41.87-2.65,3.1-2.65s3.11,1.24,3.11,2.65-.87,2.64-3.11,2.64S120.45,8.23,120.45,6.82Zm5.06,0a2,2,0,0,0-3.91,0,2,2,0,0,0,3.91,0Z"
    />
    <polygon
      style={{ fill: '#005eac' }}
      points="127.3 4.31 128.57 4.31 131.72 8.07 131.73 8.07 131.73 4.31 132.8 4.31 132.8 9.32 131.55 9.32 128.38 5.56 128.37 5.56 128.37 9.32 127.3 9.32 127.3 4.31"
    />
  </svg>
);

export default ShimizuEng;
