import React from 'react';
import styled from 'styled-components';

const BrSP = styled.br`
  /* TAB */
  @media (min-width: 768px) {
    display: none;
  }
`;

type Nl2BrTextProps = {
  text: GatsbyTypes.Maybe<string>;
  sp?: boolean;
};

/**
 * This component use to convert '\n' character in markdown text to <br /> tag.
 * If we set property sp = true, the <br /> tag will be hidden in mobile screen.
 * @param props
 * @constructor
 */
const Nl2Br = function (props: Nl2BrTextProps) {
  const regx = /(\\n)/g;
  const textes = props.text?.split(regx);
  const sp = !!props.sp;
  return (
    <>
      {textes?.map((line, index) => {
        if (line.match(regx)) {
          if (sp) {
            return <BrSP key={index} />;
          } else {
            return <br key={index} />;
          }
        }
        return line;
      })}
    </>
  );
};

export default Nl2Br;
