import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { DataContainsNode } from '../../hooks/useTransformer';
import { BaseFooter } from './BaseFooter';

const languages = ['en', 'fr', 'ja'];

const FOOTER_QUERY = graphql`
  query HiddenProjectsFooter {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          theme: { eq: "projects" }
          templateKey: { eq: "footer" }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            topLink
            lang {
              title
              text
            }
            contents {
              title
              items {
                text
                link
              }
            }
            support {
              title
              items {
                text
                link
              }
            }
            auth {
              signup {
                text
                link
              }
              signin {
                text
                link
              }
            }
            tos {
              text
              link
            }
            storeLink {
              appStore
              googlePlay
            }
          }
        }
      }
    }
  }
`;

export const ProjectsFooter: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.HiddenProjectsFooterQuery>(
    FOOTER_QUERY,
  ) as DataContainsNode;

  return <BaseFooter languages={languages} data={data} />;
};
