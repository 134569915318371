import React from 'react';
import { useLocation } from '@reach/router';
import LocalizedLink from './LocalizedLink';

type Props = {
  href: string | null | undefined;
  target?: '_blank' | '_self' | '_parent' | '_top';
  rel?: string | null | undefined;
} & React.HTMLAttributes<HTMLElement>;

const isHash = (str: string) => /^#/.test(str);
const isInternal = (to: string) => /^\/(?!\/)/.test(to);

// Only use <LocalizedLink /> for internal links
const MDXLink: React.FC<Props> = ({ href, target, rel, ...props }) => {
  const location = useLocation();
  if (location.search) {
    if (href) {
      //Can't use 'const url = new URL(href, 'relative:///');'
      const url = new URL(href, 'http://RELATIVE/');
      if (
        url.hostname.match(/^(?=.*(ricohtours.com|ricohprojects.com)).*$/) ||
        url.hostname === 'localhost' ||
        url.hostname === 'relative' ||
        !url.origin
      ) {
        const urlParamRef = new URLSearchParams(location.search).get('ref');
        if (urlParamRef) {
          if (url.search) {
            href += location.search.replace('?', '&');
          } else {
            href += location.search;
          }
        }
      }
    }

    href = href?.replace(/\/$/, '');
  }
  href = href?.replace(/^.*\.\./, '') ?? ''; // TODO
  target = target ? target : '_self';
  rel = rel ? rel : '';
  return isHash(href) || !isInternal(href) ? (
    <a {...props} href={href} target={target} rel={rel} />
  ) : (
    <LocalizedLink {...props} to={href} />
  );
};

export default MDXLink;
