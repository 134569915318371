import mergeWith from 'lodash.mergewith';
import useLocale from '../hooks/useLocale';
import useSiteMetadata from './useSiteMetadata';

export type DataContainsNode = {
  allMarkdownRemark: {
    edges: Array<{ node: Partial<GatsbyTypes.MarkdownRemark> }>;
  };
};

export const useTransformer = <Data extends DataContainsNode>(
  data: Data,
): Data => {
  // Replace {{<field>}} with site.siteMetadata.<field>
  const site = useSiteMetadata() as { [key: string]: string };
  const dataText = JSON.stringify(data).replace(
    /{{(\w+)}}/g,
    (match, p1) => site[p1] || match,
  );
  return JSON.parse(dataText);
};

interface LocalizeOption {
  country: string;
  language: string;
}
const getNode = (
  nodes: Array<Partial<GatsbyTypes.MarkdownRemark>>,
  { country = '**', language = '**' }: Partial<LocalizeOption>,
) =>
  nodes.find(
    ({ fields }) =>
      fields?.language === language && fields?.country === country,
  );
// Merge node by language and country
const getLocalizedNode = (
  nodes: Parameters<typeof getNode>[0],
  { country, language }: Parameters<typeof getNode>[1],
) =>
  mergeWith(
    getNode(nodes, {}),
    getNode(nodes, { language }),
    getNode(nodes, { country }),
    getNode(nodes, { country, language }),
    (a: unknown, b: unknown) => (b == null || b === '' ? a : undefined),
  );
export const useTransformNode = <Data extends DataContainsNode>(
  data: Data,
): Data['allMarkdownRemark']['edges'][0]['node'] => {
  const { country, language } = useLocale();
  const { allMarkdownRemark } = useTransformer(data);

  const nodes = allMarkdownRemark.edges.map((edge) => edge.node);
  const localizedNode = getLocalizedNode(nodes, { country, language });

  if (!localizedNode) {
    throw new Error('Localized node not found');
  }
  return localizedNode;
};

export default useTransformer;
