import React from 'react';

const Ricoh360Prjects: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 699.08 100">
    <rect x="0.16" width="698.92" height="100" fill="none" />
    <path d="M0,22.81c6.89.15,13.78.3,20.67.5a22.7,22.7,0,0,1,5.15.9c7.14,1.95,10.53,6.54,10.63,14,0,6.24-3.69,11-10.28,13l-1.35.44c4,2.1,5.39,5.85,7,9.29,2.94,6.24,5.89,12.44,8.79,18.68a4,4,0,0,1,.05.8c-2.7,0-5.45.1-8.14-.1-.6,0-1.35-1.1-1.65-1.8-2.5-5.49-5.14-11-7.34-16.63-2.05-5.29-5.54-7.64-11.09-7.09-1.29.1-2.59,0-4.14,0V80.29L0,80.74ZM8.24,30V48.23c4.84-.6,9.34-.7,13.58-1.75,4.05-1,5.94-4.34,5.74-8.29a7.37,7.37,0,0,0-6.64-7.29C16.93,30.25,12.73,30.3,8.24,30Z" />
    <path d="M699.08,71.25c-4.34,9.64-14.88,13.28-24.77,8.54a3,3,0,0,1-1.3-2,33.67,33.67,0,0,1,.2-5c2.6.8,5,1.89,7.59,2.14a14.62,14.62,0,0,0,7-.94c3.65-1.55,4-6.1,1-8.59a28.34,28.34,0,0,0-4.24-2.6c-2.1-1.15-4.3-2.2-6.34-3.4-3.9-2.34-6-5.69-5.55-10.33A11.5,11.5,0,0,1,680,38.94c4.6-2,11-1.8,17,.4q-.3,3.07-.6,6.29a38.89,38.89,0,0,0-5.89-1.5,19.36,19.36,0,0,0-5.64.25,4.5,4.5,0,0,0-3.9,3.7,4.87,4.87,0,0,0,2.2,5.19c1.7,1.15,3.6,2,5.44,3,2.1,1.15,4.5,2,6.2,3.59s2.84,4,4.19,6v5.44Z" />
    <path d="M543.12,23v8.09h-8V23Z" />
    <path d="M176.62,50.68c-.25,9.33-2,16.92-7.74,22.87-8.24,8.38-18.43,9.53-29.11,6.59C129.43,77.24,123.84,69.6,122,59.36c-1.75-9.53-.95-18.87,5-26.91,7.14-9.64,17.23-11.93,28.47-9.74s17.67,9.89,20.22,20.88A69.52,69.52,0,0,1,176.62,50.68ZM167.83,52a26.9,26.9,0,0,0-3.5-14.08c-6.94-11.63-24.11-11.78-30.81,0a27.09,27.09,0,0,0-.1,27.67c6.55,11.83,24.17,11.68,31,0A27.22,27.22,0,0,0,167.83,52Z" />
    <path d="M224.5,54.37H196.94V80.29h-8.09V23.06h8V47h5c6.75,0,13.49-.05,20.23,0,1.85,0,2.44-.5,2.39-2.4-.1-6.24-.05-12.48-.05-18.72v-2.9h8V80.29h-8V54.37Z" />
    <path d="M374.56,51.62c0,6.55-.7,12.94-3.45,19C368,77.49,362.77,81.44,355,81.44S341.9,77.59,339.1,70.65a49.39,49.39,0,0,1,0-37.9C341.9,25.81,347.39,22,355,22s12.89,3.85,16,10.69C373.76,38.69,374.56,45.08,374.56,51.62Zm-8.24.4-.4-.1a57.42,57.42,0,0,0-.55-7.19,61,61,0,0,0-2.7-10.28c-1.25-3.35-3.74-5.7-7.74-5.7-3.84,0-6,2.4-7.59,5.55a20.26,20.26,0,0,0-1.25,3,56.18,56.18,0,0,0,0,28.87c1.5,5.59,4.85,8.58,9,8.58s7.49-2.89,8.84-8.48C365.12,61.61,365.57,56.77,366.32,52Z" />
    <path d="M324.62,31c-6.84-2.49-13.43-3.89-19.37,1.1-5.2,4.34-6.59,10.44-6.79,17.53,3.54-3.2,7.09-5,11.33-5.25,10.94-.69,18.43,6.45,18.68,17.68.3,14-12,23-25.32,18.33-5.59-2-8.89-6.14-10.44-11.59a51,51,0,0,1,1-32.8c3.8-10.14,12.19-15,23-13.84a72.92,72.92,0,0,1,7.39,1.2c.6.15,1.5,1,1.5,1.45C325.37,26.81,325,28.8,324.62,31Zm-4.39,32.11c-.55-5.69-3.35-10.29-7.44-11.54a10.43,10.43,0,0,0-11.44,4c-3,4.59-2.49,11.83,1.25,16,4.1,4.54,11.29,4.34,14.58-.8C318.68,68.45,319.23,65.61,320.23,63.06Z" />
    <path d="M415.9,57.47V80.34h-8V23.06c5.8,0,11.49-.2,17.18.1a34.32,34.32,0,0,1,9.24,1.75c6.44,2.2,9.59,7.09,10,13.78.4,6.89-1.69,12.64-8.08,16.13-4.15,2.3-8.69,2.65-13.29,2.7C420.75,57.47,418.5,57.47,415.9,57.47ZM416,29.8V50.23c3.3,0,6.19.05,9.14,0a13.19,13.19,0,0,0,2.45-.4c4.84-1.1,7.74-4.3,8-8.89s-2-8.29-7-9.59C424.64,30.35,420.45,30.3,416,29.8Z" />
    <path d="M244.63,79.39c.2-2.75.4-5,.6-7.29,3.19.85,6.24,1.8,9.38,2.39A19.19,19.19,0,0,0,266.5,72.9c3.34-1.6,5.29-4.15,5.24-8s-1.4-7.39-5.19-8.89c-3.05-1.15-6.39-1.45-9.64-2a31,31,0,0,0-4.34,0V47.43c2.89-.2,5.69-.3,8.44-.65a19.22,19.22,0,0,0,4.74-1.35c4.09-1.74,5.79-4.59,5.39-8.84-.35-3.64-2.74-6.34-6.79-7.34-5-1.19-9.89-.35-14.63,1.45l-2.5,1c-.1-1.25-.2-2.3-.25-3.34-.29-4.1-.29-4.1,3.85-5.05,5.74-1.3,11.48-1.85,17.28-.5,10,2.35,15.08,13,10.08,21.22-2.24,3.6-5.74,5.4-10.18,6.7,1.55.6,2.64,1,3.69,1.39,5.59,2.25,8.59,6.4,8.84,12.39s-1.95,11.13-7.64,14c-6.44,3.2-13.33,3.3-20.22,2.45A80.56,80.56,0,0,1,244.63,79.39Z" />
    <path d="M113.75,71.85c.1,1.4.15,2.79.3,4.19.3,3.6.35,3.8-3.15,4.4-11.18,2.09-22.12,1.69-31-6.59-9.58-9-11.08-20.43-7.44-32.41C76.1,29.7,85,23.86,96.87,22.26a34.59,34.59,0,0,1,15.48,1.45c1.3.45,2,.95,1.7,2.45-.3,1.95-.35,3.94-.5,6-13.08-4.79-22.72-3.1-29.11,4.89C78.6,44.23,78,56.12,83,64.26,88.68,73.55,97.17,75.64,113.75,71.85Z" />
    <path d="M594.22,61.26H564.36a13.4,13.4,0,0,0,9.48,13.38c4.2,1.3,7.49.7,17-3.14,0,2.25.1,4.34-.05,6.44,0,.5-.85,1.15-1.45,1.35A30.54,30.54,0,0,1,570,80.49a17.12,17.12,0,0,1-12.58-11.74c-2.2-6.59-2.4-13.23.3-19.72,4.34-10.49,17.62-14.93,27.46-9.24,5.64,3.3,7.54,8.79,8.44,14.83C594,56.67,594,58.82,594.22,61.26Zm-8.59-6c.3-6.24-3.8-11.14-9.39-11.48-6.54-.35-11.58,4.49-11.83,11.48Z" />
    <path d="M504,81.44c-12.24,0-21.53-9.54-21.43-22.08.05-12.88,8.94-22,21.48-21.92s21.32,9.14,21.27,22.07C525.31,72.05,516.17,81.44,504,81.44ZM490.5,59.66c.65,2.55,1,5.25,2,7.64,1.95,4.75,5.54,7.64,10.84,7.89A11.63,11.63,0,0,0,515,68.25a18.45,18.45,0,0,0-.1-17.52c-4.59-8.74-15.63-9.49-21-1.55A21.87,21.87,0,0,0,490.5,59.66Z" />
    <path d="M665.52,74.1a7.74,7.74,0,0,1,.1.89c.15,5.9-.1,6.15-6,6.4-8.84.35-13.43-4-13.48-12.74-.05-7,0-14,0-21v-2.9h-9.29V38.49h9.29c0-2.74,0-5.34-.05-7.89,0-1.35.3-2.14,1.75-2.49,1.89-.45,3.79-1.15,5.94-1.85v12h11v6.34H654a20.19,20.19,0,0,0-.2,2.15v20a13.65,13.65,0,0,0,.35,3.2A6.33,6.33,0,0,0,661.73,75,27.24,27.24,0,0,0,665.52,74.1Z" />
    <path d="M631.17,45.33c-10.19-2.74-15.23-1.54-19,4.3-4.09,6.24-3.69,15.53.85,21.07,4,4.79,10.19,5.64,18.58,2.4.2,2.24.4,4.39.6,6.49-12.84,5.34-29.06-.65-31-17.43-1.15-10.49,3.44-19,11.73-22.72,5.65-2.55,11.44-2.45,17.28-.75a2.41,2.41,0,0,1,1.3,1.85A23,23,0,0,1,631.17,45.33Z" />
    <path d="M535.58,40.17h7a7.58,7.58,0,0,1,.24,1.15c0,14.42-.06,28.79-.12,43.22a21.33,21.33,0,0,1-.48,3.92c-1.09,5.37-4.53,8.27-10,8.39-1.57.06-3.08,0-4.77,0V90.69c.36-.18.48-.3.6-.3,5,.3,6.64-1,7.37-6,.12-1.27.18-2.54.18-3.75V40.17Z" />
    <path d="M58.92,80.39H51.08V23.06h7.84Z" />
    <path d="M453.5,80.39V38.59h7v6.29l1.75-2.24c3.4-4.4,7.89-5.9,13.23-4.75a2,2,0,0,1,1.3,1.35c.15,1.8.05,3.65.05,5.59l-1.9-.35c-5.54-.89-10.18,1.5-12,7A47.32,47.32,0,0,0,461,62.66c-.3,5-.1,10.14-.1,15.18v2.55Z" />
  </svg>
);

export default Ricoh360Prjects;
