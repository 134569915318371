import React from 'react';

const Ricoh360: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520.93 100">
    <rect width="520.93" height="100" fill="none" />
    <path d="M8.51,44.91h7.25c8.08,0,12.87-3.58,12.87-9.28,0-5.36-4.09-8.76-11.93-8.76H8.51v18.04ZM0,19.38H14.56c11.75,0,23.09,1.87,23.09,16.09,0,7.23-5.36,12.34-12.26,13.52v.16c2.65,1.02,3.91,2.3,5.78,5.95l11.24,23.66h-9.71l-9.12-20.59c-2.65-5.78-5.46-5.78-9.96-5.78h-5.11v26.37H0V19.38Z" />
    <polygon points="50.9 78.77 59.39 78.77 59.39 19.38 50.9 19.38 50.9 78.77" />
    <path d="M114.64,30.13c-3.69-2.2-7.78-3.3-12.05-3.3-13.48,0-22.44,9.81-22.44,23.09s9.88,23.09,22.01,23.09c4.19,0,9.79-1.1,12.73-2.87l.51,8.21c-4.44,1.85-9.88,2.12-13.32,2.12-18.51,0-30.81-12.01-30.81-30.81s13.05-30.28,30.89-30.28c4.52,0,8.96,.67,13.13,2.55l-.67,8.21h.02Z" />
    <path d="M149.31,73.01c13.83,0,19.75-11.34,19.75-23.09s-6.52-23.19-19.75-23.09c-13.21-.08-19.75,11.08-19.75,23.09s5.93,23.09,19.75,23.09m0-53.63c18.2,0,28.85,13.03,28.85,30.54s-10.57,30.54-28.85,30.54-28.85-12.6-28.85-30.54,10.65-30.54,28.85-30.54" />
    <polygon points="188.34 19.38 196.92 19.38 196.92 44.22 225.58 44.22 225.58 19.38 234.16 19.38 234.16 78.77 225.58 78.77 225.58 51.71 196.92 51.71 196.92 78.77 188.34 78.77 188.34 19.38" />
    <polygon points="285.02 26.87 266.39 26.87 266.39 19.38 312.2 19.38 312.2 26.87 293.59 26.87 293.59 78.77 285.02 78.77 285.02 26.87" />
    <polygon points="319 19.38 327.58 19.38 327.58 44.22 356.22 44.22 356.22 19.38 364.82 19.38 364.82 78.77 356.22 78.77 356.22 51.71 327.58 51.71 327.58 78.77 319 78.77 319 19.38" />
    <polygon points="380.09 19.38 412.32 19.38 412.32 26.87 388.33 26.87 388.33 44.22 410.18 44.22 410.18 51.71 388.33 51.71 388.33 71.28 412.32 71.28 412.32 78.77 380.09 78.77 380.09 19.38" />
    <polygon points="439.42 26.87 420.81 26.87 420.81 19.38 466.62 19.38 466.62 26.87 447.99 26.87 447.99 78.77 439.42 78.77 439.42 26.87" />
    <path d="M491.06,27.38l-11.83,29.44h23.66l-11.83-29.44Zm-4.17-8h8.94l25.11,59.39h-9.26l-6.05-14.99h-29.1l-6.05,14.99h-8.94l25.35-59.39Z" />
  </svg>
);

export default Ricoh360;
