import React from 'react';

const Hibiya: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.93 45" height={45}>
    <path d="M83.86,42.11l-2,2.07-1.25-1.07c.49-.46,1-.88,1.49-1.39a20.15,20.15,0,0,0,1.21-1.66l-.08-.14a3.89,3.89,0,0,0-1.45,0c-.41.17-.69.65-1.1,1.08l-.41-.59-.11.07v4.3H78.6V41.6l-.08,0-.68,1.07-1-1.54a7.78,7.78,0,0,0,1.55-3.42H77.19V36.07h1.39V34.3h1.73L80.14,36l1.1.06V37l.18,0,.76-2.26,1.44.34-.37.91h.61V34.16l1.77.09L85.41,36h2.2v1.43H85.52v1h2.27V39.9H86a5.63,5.63,0,0,0,2.27,2.58L87.17,44l-1.68-1.86v2.73H83.86Zm-1.48-3.8a6.82,6.82,0,0,0-2.14-.65l-.12.14,1.29,1.71.15-.06v-1h2.27v-.94C83,37.28,82.54,37.53,82.38,38.31Z" />
    <path d="M36.57,36.18c-.12.06-.29.08-.35.18-.34.52-.65,1.06-1,1.68l.93-.34L36.75,40c-.53,0-.9.57-1.48.2l0,.52,1.15-.25.14.76,1.28.11.08-.55,1.16.07-.16-.35.5-.54c-.76.14-1.43-.05-2,.35,0,0-.31-.1-.34-.19-.14-.46-.23-.94-.35-1.43a1,1,0,0,1,.2-.07c.64.11.92-.25,1.1-.79s.48-1.29.74-2l1.67.58-1.2,2.05h1.15l-.42-.74,1.22-.64,1.65,2.58-1.43.86c-.13-.59-.42-.92-1.08-.59l.67.89-1,1-.5-.61c0,.72,0,1.34,0,2a.3.3,0,0,0,.19.22.31.31,0,0,0,.23-.16c.1-.23.16-.47.23-.68l1.13.53L41.3,43l-.63-1.37L42,41l1,2.22-1.35.7-.22-.47s-.07,0-.07,0c-.19.47-.22,1.13-.8,1.25a8.57,8.57,0,0,1-2.09,0,.63.63,0,0,1-.55-.67c0-.47,0-.94,0-1.41a1.43,1.43,0,0,0-.06-.5L37,44.24l-1.24-.63.4-.79-.59.12-.29-1.66h-.06v3.5H33.69v-3.3H33.6L33.08,44l-1.2-.37.57-2.73,1.19.16v-.72c-.39.07-.81-.11-1.15.28-.18.2-.31.07-.34-.14-.07-.47-.13-.93-.21-1.46a4.36,4.36,0,0,0,.73,0,1,1,0,0,0,.5-.26c.43-.57.42-.58-.15-1-.17-.14-.35-.27-.53-.4L32,37l.79-1.37.37.11.76-1.65,1.6.63c-.79.46-.94,1.22-1.28,1.84,0,0,.12.11.24.21l.66-1.34c.44.16.89.31,1.33.49.07,0,.1.17.15.26ZM35.24,39,35,38.27l-.52.8Z" />
    <path d="M111.35,36.36h1.38V34.3h1.81l-.09,2a4.83,4.83,0,0,1,1.25.26c.3.17.77.4.45,1h1.7V34.3h2.09a3.39,3.39,0,0,0-.25,1.88c0,.42,0,.85,0,1.33h1.82V39.2h-1.78V43h2.12v1.59h-6.64V43h2.62V39.22H116V38.08h-.09l-.73,1.2,1.55,1.35-1.07,1.27L114.5,41v3.84h-1.75v-3l-.88.66-1-1.43a9.6,9.6,0,0,0,3-3h-2.49Z" />
    <path d="M63.59,34.41c0,.81,0,1.59,0,2.37a1.46,1.46,0,0,0,.18.43,1,1,0,0,0,.45-.87l1.21.62c-.06.27-.12.58-.21.87-.22.68-.45.84-1.17.84h-.94c-.74,0-1.08-.32-1.12-1.08,0-.42,0-.85,0-1.27,0-.16,0-.31,0-.49h-.74a4.77,4.77,0,0,1-1.08,3.33c1.29,0,2.47,0,3.64,0a1.05,1.05,0,0,1,.54.18.69.69,0,0,1,.19,1c-.42.68-.8,1.38-1.24,2.17l2.1.87-1,1.34c-.64-.29-1.31-.57-2-.9a.69.69,0,0,0-.8,0c-.61.4-1.28.71-1.93,1.06l-1-1.39-.06,1.08H57.26c-.06,0-.16,0-.16-.06-.08-.47-.44-.2-.7-.28l-.09.5H54.79v-4h3.85v2.79l2.09-.9-1.19-1.63.53-.41-1-.06V39.19l.83,0L60,39l-1.26-.71c1.17-1.07.74-2.54.86-3.91Zm-1.65,7.17.64-.94H61.14Zm-4.9.48h-.67v.84H57Z" />
    <path d="M0,34.91H8.24v9.4H6.45l-.08-.6H1.83l-.07.6H0ZM1.81,42H6.38V40H1.81Zm4.58-5.45H1.83v1.84H6.39Z" />
    <path d="M68.38,43.16a4.15,4.15,0,0,0,.67-2.28c0-.67,0-1.35,0-2,0-.22.15-.58-.3-.6,0,0,0-.14,0-.22,0-.36,0-.72,0-1.14h1.88c.13-.35,0-.49-.29-.48h-1V35.06h1.39l0-.77h1.88l-.4.73h1.06l0-.72h1.85l-.36.74h1.44v1.38c-.32,0-.66,0-1,0s-.44.09-.33.46h1.55v1.4H70.57c0,2.18.2,4.36-1,6.3l-1.14-.79v1h-1.6V39.15l-.42.33-.93-1.3a11.72,11.72,0,0,0,2.41-4.07l1.58.57c-.32.59-.62,1.12-.9,1.67,0,.11,0,.26,0,.39s.07.19,0,.22c-.35.4-.17.87-.18,1.3C68.37,39.9,68.38,41.53,68.38,43.16Zm3.89-6.3h1c0-.49-.14-.55-1-.37Z" />
    <path d="M31.67,40.34l-1,1.4-1.1-.47v3.55c-.58,0-1.23.2-1.61,0-1-.62-2-.13-3-.29-.14,0-.3.13-.44.21a1.31,1.31,0,0,1-.28.12H22.67V41.48l-.86.48L20.49,40.7a10.35,10.35,0,0,0,4.8-4.76l2.09.39c-.4.38-.12.64.07.91a9.12,9.12,0,0,0,3.32,2.65Zm-4,1.46H24.5v1.08h3.2Zm.11-1.66-1.76-1.92-1.7,1.92Z" />
    <path d="M88.59,37.87V36.25H93.5l-.12-2.1,1.91.15c0,.6-.07,1.11-.1,1.61,0,.24.1.33.34.33a7.58,7.58,0,0,0,1.31-.1l-1.14-.91,1-1.21L98.4,35.2l-.74,1h1.46v1.63H95.38A17.6,17.6,0,0,0,96,40a17.83,17.83,0,0,0,1.27,2.3c.25.39.44.33.61-.1.08-.23.15-.47.23-.71.45.32.87.63,1.29.95a.26.26,0,0,1,.06.24,9.41,9.41,0,0,1-.73,1.61,1.15,1.15,0,0,1-1.54.26,4.31,4.31,0,0,1-1.62-1.66,12.8,12.8,0,0,1-1.69-4.67c-.06-.33-.19-.4-.49-.4H88.59Z" />
    <path d="M110,43.64l-1.39,1.28a6.58,6.58,0,0,1-.46-.66c-.21-.41-.52-.44-.93-.39-1.9.23-3.8.43-5.7.66a3.34,3.34,0,0,0-.77.3l-.4-2c.54,0,1,0,1.51,0a.57.57,0,0,0,.4-.22c.22-.44.41-.91.64-1.43h-2.44V39.6h9.46v1.51h-2.22Zm-3.46-2.47c-1.5-.17-1.93.13-2.26,1.5l2.25-.23-.68-.64Z" />
    <path d="M15,34.11l2.09.13c-.59,1-.18,2-.33,3l.11.07,1.85-1.56,1.54,1.42c-1.1.75-2.16,1.45-3.2,2.18a.64.64,0,0,0-.27.46c0,.84,0,1.67,0,2.5,0,.38.15.54.52.56,1.14.05,1.25,0,1.37-1.18l0-.28.83.46c.27.12.55.22.95.38a10.14,10.14,0,0,1-.53,1.7,1.27,1.27,0,0,1-.86.62,21.6,21.6,0,0,1-3,0A1.07,1.07,0,0,1,15,43.49C15,40.4,15,37.3,15,34.11Z" />
    <path d="M49.63,34.4a2.47,2.47,0,0,0,.3.66,11.51,11.51,0,0,0,3.83,2.76l.42.2s0,0,0,.06l-1.09,1.48-2-1.17-.06.68H45.93l-.1-.51L44,39.69l-1.22-1.46c.48-.26,1-.5,1.44-.78a8.43,8.43,0,0,0,3-3,.57.57,0,0,1,.64-.34c.6.07,1.21.09,1.82.13Zm.24,3.15L48.43,36,47,37.55Z" />
    <path d="M110.75,37.35l-1.12,1.48-1.51-.7v1h-5.86v-.88l-1.35.67-1.28-1.4a9.21,9.21,0,0,0,3.26-2,17.74,17.74,0,0,0,1.23-1.36c.08-.08.18-.22.26-.22.7.07,1.39.17,2.09.27l-.13.13a2.37,2.37,0,0,0,.3.57,9.49,9.49,0,0,0,3.41,2.13Zm-7.54.26h4l-2.13-1.76Z" />
    <path d="M74.15,43.86l0,.71H72.9V43.13h-.62v1.65H70.92V38.7h5.25v3.22c0,.48,0,1,0,1.46,0,1.16-.3,1.45-1.52,1.45l-.4-1ZM72.3,39.92v.46h.57v-.46Zm.6,1.61h-.61V42h.58Zm1.23-1.61,0,.47h.56v-.47Zm.6,1.61h-.57V42h.57Zm-.55,1.6c-.13.32,0,.44.29.41s.22-.2.33-.3l-.09-.11Z" />
    <path d="M12.4,37.35h2.23v1.78H12.45V42.5l2.3-.48c-.06.49-.09,1-.17,1.41a.57.57,0,0,1-.34.3c-1,.28-2.07.53-3.1.8l-1.43.41-.43-1.85c.32-.06.58-.14.84-.16s.45-.18.45-.53q0-3.84,0-7.69V34.2h2.09C12.22,35.27,12.51,36.29,12.4,37.35Z" />
    <path d="M44.91,39.82H52.1v5H50.46l-.11-.44H46.66l-.1.46H44.91Zm1.72,3h3.75V41.35H46.63Z" />
    <path d="M93.8,40.58H92.35a2.93,2.93,0,0,0,0,.35v1.7l1-.18,1-.17c0,.46,0,.91-.1,1.35,0,.09-.18.2-.29.22-1.42.27-2.84.51-4.26.77a1.09,1.09,0,0,0-.49.25c-.18.18-.32.17-.38,0-.14-.54-.24-1.09-.36-1.67l2-.24V40.58H88.71V39.09H93.8Z" />
    <path d="M31.32,36.82,30,38l-3.11-3,1.31-.94Z" />
    <path d="M25.22,34.9c-.37.42-.67.78-1,1.12-.62.63-1.25,1.25-1.85,1.84L21,36.9l2.69-2.8Z" />
    <path d="M58.94,36.06v1.21H54.37V36.06Z" />
    <path d="M36.57,36.18v0l1.12-1.92,1.5.58c-.29.45-.53.85-.79,1.23l-1.09,1.68L36,36.91Z" />
    <path d="M58.34,35.67H54.87V34.42h3.47Z" />
    <path d="M42.82,36.51l-1.3.88L39.79,35l1.3-.8Z" />
    <path d="M58.34,37.66v1.15H54.87V37.66Z" />
    <path d="M54.88,39.24h3.45v1.15H54.88Z" />
    <path
      style={{ fill: '#fff' }}
      d="M82.38,38.31c.16-.78.66-1,1.45-.79v.94H81.56v1l-.15.06L80.12,37.8l.12-.14A6.82,6.82,0,0,1,82.38,38.31Z"
    />
    <path style={{ fill: '#fff' }} d="M35.24,39l-.74,0,.52-.8Z" />
    <path style={{ fill: '#fff' }} d="M61.94,41.58l-.8-.94h1.44Z" />
    <path style={{ fill: '#fff' }} d="M57,42.06v.84h-.67v-.84Z" />
    <path style={{ fill: '#fff' }} d="M1.81,42V40H6.38v2Z" />
    <path style={{ fill: '#fff' }} d="M6.39,36.54v1.84H1.83V36.54Z" />
    <path style={{ fill: '#fff' }} d="M72.27,36.86v-.37c.82-.18,1-.12,1,.37Z" />
    <path style={{ fill: '#fff' }} d="M27.7,41.8v1.08H24.5V41.8Z" />
    <path style={{ fill: '#fff' }} d="M27.81,40.14H24.35l1.7-1.92Z" />
    <path
      style={{ fill: '#fff' }}
      d="M106.56,41.17l-.69.63.68.64-2.25.23C104.63,41.3,105.06,41,106.56,41.17Z"
    />
    <path style={{ fill: '#fff' }} d="M49.87,37.55H47L48.43,36Z" />
    <path style={{ fill: '#fff' }} d="M103.21,37.61l1.91-1.76,2.13,1.76Z" />
    <path style={{ fill: '#fff' }} d="M72.3,39.92h.57v.46H72.3Z" />
    <path style={{ fill: '#fff' }} d="M72.9,41.53l0,.46h-.58v-.46Z" />
    <path style={{ fill: '#fff' }} d="M74.13,39.92h.59v.47h-.56Z" />
    <path style={{ fill: '#fff' }} d="M74.73,41.53V42h-.57v-.45Z" />
    <path
      style={{ fill: '#fff' }}
      d="M74.18,43.13h.53l.09.11c-.11.1-.21.29-.33.3S74.05,43.45,74.18,43.13Z"
    />
    <path style={{ fill: '#fff' }} d="M46.63,42.8V41.35h3.75V42.8Z" />
    <path
      style={{ fill: '#003d8c' }}
      d="M69.83,23.18c0-1.5,0-3,0-4.48a2,2,0,0,1,1.47-2.11,1.91,1.91,0,0,1,2.43,1.78q0,3.52,0,7c0,.48.13.63.61.62,1.6,0,3.21,0,4.81,0a1.65,1.65,0,1,0,0-3.3,1.92,1.92,0,0,1-1.6-2.84,1.83,1.83,0,0,1,1.54-1c1-.08,1.59-.52,1.76-1.28a1.67,1.67,0,0,0-1.71-2c-.84,0-1.69,0-2.54,0a1.85,1.85,0,0,1-1.9-1.76,1.83,1.83,0,0,1,1.76-2.07,19.09,19.09,0,0,1,3.86.1,5.48,5.48,0,0,1,3.41,8.66.5.5,0,0,0,0,.69,5.54,5.54,0,0,1-3.65,8.7,9.65,9.65,0,0,1-1.32.06c-2.23,0-4.46,0-6.69,0a2.09,2.09,0,0,1-2.29-2.31C69.85,26.14,69.83,24.66,69.83,23.18Z"
    />
    <path
      style={{ fill: '#003d8c' }}
      d="M38.06,12.91c-11.89-3.64-22.34-1.26-31.46,7l.07.17c.17-.13.34-.25.5-.39A30.6,30.6,0,0,1,38,13.8a1.07,1.07,0,0,1,.76.79c.08.3.19.59.3.95a30.29,30.29,0,0,0-23.47-.17A29.78,29.78,0,0,0,4.76,22.83l.07.1a3.15,3.15,0,0,0,.29-.22,30.42,30.42,0,0,1,15-8.12,30,30,0,0,1,9.41-.54A31.34,31.34,0,0,1,39,16.5a.79.79,0,0,1,.54.63c.13.66.3,1.31.47,2a30.32,30.32,0,0,0-21.85-2.53,31.2,31.2,0,0,0-18,12.65c.44-1,.83-2.07,1.33-3.07A30.35,30.35,0,0,1,17.75,11.13a28.93,28.93,0,0,1,7.74-1.89,30.56,30.56,0,0,1,10.61.68,1.08,1.08,0,0,1,.81.62C37.27,11.33,37.67,12.11,38.06,12.91Z"
    />
    <path
      style={{ fill: '#003d8c' }}
      d="M106.38,27.94a5,5,0,0,1,.19-.76q2.87-7,5.75-14A2,2,0,0,1,113.6,12a1.91,1.91,0,0,1,2.4,1.2l3.41,8.27q1.19,2.87,2.36,5.75a1.93,1.93,0,0,1-2.88,2.36,2.71,2.71,0,0,1-.75-1c-.36-.76-.64-1.57-1-2.34a.57.57,0,0,0-.43-.31c-1.7,0-3.4,0-5.09,0a.48.48,0,0,0-.51.38c-.32.81-.64,1.61-1,2.4a2,2,0,0,1-3.78-.75Zm7.85-9h-.13l-1.52,3.79h3.17Z"
    />
    <path
      style={{ fill: '#003d8c' }}
      d="M48.85,20.36c0,.19,0,.36,0,.53v6.84a2.09,2.09,0,0,1-.71,1.72,1.86,1.86,0,0,1-2.07.28A1.8,1.8,0,0,1,44.92,28V18.44a1.91,1.91,0,0,1,2-2H57.87a1.92,1.92,0,0,1,2,2v6.22c0,1.15,0,2.3,0,3.44a1.83,1.83,0,0,1-1.62,1.75,1.89,1.89,0,0,1-2.15-1.35,3,3,0,0,1-.08-.79q0-3.46,0-6.93c0-.37-.09-.49-.47-.48-2.1,0-4.19,0-6.28,0Z"
    />
    <path
      style={{ fill: '#003d8c' }}
      d="M8.43,15.49V0h6.39a2.73,2.73,0,0,1,0,.38V10.83c0,.24,0,.44-.28.58a29.38,29.38,0,0,0-5.8,3.82Z"
    />
    <path
      style={{ fill: '#003d8c' }}
      d="M101.29,20c.86-1,1.66-1.83,2.38-2.75a1.66,1.66,0,0,1,1.62-.75,15.54,15.54,0,0,0,1.93,0c.34,0,.66,0,.85.36s0,.65-.24.92c-1.43,1.84-2.86,3.67-4.27,5.52a1.49,1.49,0,0,0-.28.85c0,1.21,0,2.42,0,3.63a1.92,1.92,0,0,1-1.92,2.1,2,2,0,0,1-2-2.09c0-1.21,0-2.43,0-3.63a1.53,1.53,0,0,0-.34-1q-2.15-2.72-4.25-5.47a1,1,0,0,1-.16-.84c.08-.19.48-.33.74-.34.83,0,1.66,0,2.5,0a.87.87,0,0,1,.76.35c.78.92,1.59,1.82,2.38,2.74C101.09,19.7,101.16,19.83,101.29,20Z"
    />
    <path
      style={{ fill: '#003d8c' }}
      d="M62.94,20.77c0-2.36,0-4.72,0-7.07a2,2,0,1,1,3.9,0V27.91a2,2,0,1,1-3.9,0C62.93,25.51,62.94,23.14,62.94,20.77Z"
    />
    <path
      style={{ fill: '#003d8c' }}
      d="M91.75,20.8c0,2.36,0,4.72,0,7.07a2,2,0,1,1-3.9.05c0-3,0-6,0-9,0-1.79,0-3.59,0-5.38a2,2,0,0,1,1.46-1.87,1.93,1.93,0,0,1,2.19,1,2.51,2.51,0,0,1,.24,1.08C91.76,16.12,91.75,18.46,91.75,20.8Z"
    />
    <path
      style={{ fill: '#003d8c' }}
      d="M14.83,18.85v11H8.43c0-.16,0-.31,0-.46q0-3.35,0-6.69a.75.75,0,0,1,.25-.55,26.29,26.29,0,0,1,6-3.28A.39.39,0,0,1,14.83,18.85Z"
    />
    <path
      style={{ fill: '#003d8c' }}
      d="M93.78,13.58a1.85,1.85,0,0,1,2-1.88,1.93,1.93,0,1,1-2,1.88Z"
    />
    <path
      style={{ fill: '#119b56' }}
      d="M105,13.58a1.93,1.93,0,0,1,3.86,0,1.93,1.93,0,1,1-3.86,0Z"
    />
    <path
      style={{ fill: '#003d8c' }}
      d="M46.88,11.7a1.93,1.93,0,1,1,0,3.86,1.89,1.89,0,0,1-1.92-2A1.86,1.86,0,0,1,46.88,11.7Z"
    />
    <path
      style={{ fill: '#119b56' }}
      d="M58,11.7a1.93,1.93,0,1,1,0,3.86,1.89,1.89,0,0,1-1.92-2A1.87,1.87,0,0,1,58,11.7Z"
    />
    <path
      style={{ fill: '#119b56' }}
      d="M71.79,11.7a1.93,1.93,0,1,1,0,3.86,1.9,1.9,0,0,1-1.93-2A1.87,1.87,0,0,1,71.79,11.7Z"
    />
    <path style={{ fill: '#fff' }} d="M114.23,19l1.52,3.78h-3.17l1.52-3.79Z" />
    <path
      style={{ fill: '#119b56' }}
      d="M26.61,0h6.56V8.36a25.9,25.9,0,0,0-6.56-.17Z"
    />
    <path
      style={{ fill: '#119b56' }}
      d="M26.61,16.68V29.89h6.55V17.59A27.71,27.71,0,0,0,26.61,16.68Z"
    />
  </svg>
);

export default Hibiya;
