import React from 'react';
import { ProjectsHeader } from './ProjectsHeader';

type Props = {
  calendlyModalIsOpen?: boolean;
  updateCalendlyModalIsOpen?: (bool: boolean) => void;
  promotionalPage?: 'gen' | 'remax' | 'immovlan';
};

export const Header: React.FC<Props> = ({
  calendlyModalIsOpen,
  updateCalendlyModalIsOpen,
}) => {
  return (
    <ProjectsHeader
      calendlyModalIsOpen={calendlyModalIsOpen}
      updateCalendlyModalIsOpen={updateCalendlyModalIsOpen}
    />
  );
};
