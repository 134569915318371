import React from 'react';

const useIntercomBanner = () => {
  const [shown, setShown] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const [zIndex, setZIndex] = React.useState(0);

  const onMutation = React.useCallback(() => {
    // <iframe ... name="intercom-banner-frame" data-intercom-frame="true" role="modal">
    const bannerEl = document.querySelector<HTMLElement>(
      '[name=intercom-banner-frame]',
    );

    // Intercom banner style "Inline" and "Top" offsets body margin-top
    const marginTop = document.body.style.marginTop;

    // Treat as a banner shown when both iframe and body margin-top exist
    const bannerShown = bannerEl !== null && marginTop !== null;
    setShown(bannerShown);
    setOffset((bannerShown && parseInt(marginTop)) || 0);
    setZIndex(
      (bannerEl && parseInt(window.getComputedStyle(bannerEl).zIndex, 10)) || 0,
    );
  }, []);

  React.useEffect(() => {
    const observer = new MutationObserver(onMutation);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['style'],
    });
    return () => {
      observer.disconnect();
    };
  }, [onMutation]);

  return { shown, offset, zIndex };
};

export default useIntercomBanner;
