import React from 'react';

const Njs: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170.57 45" height={45}>
    <polygon
      style={{ fill: '#231815' }}
      points="96.66 44.2 79.4 15.45 79.4 44.2 70.61 44.2 70.61 0.8 79.4 0.8 96.71 29.64 96.71 0.8 105.45 0.8 105.45 44.2 96.66 44.2"
    />
    <path
      style={{ fill: '#231815' }}
      d="M121.1,44.9c-3.43-.11-7.14-2.21-10.16-4.22l4.59-6.85c2.34,1.62,3.71,2.3,5.9,2.32h0a5.2,5.2,0,0,0,5-3.23c.55-1.54.54-4.36.54-6.63V.8h8.34V25.48C135.34,33.81,133.86,44.9,121.1,44.9Z"
    />
    <path
      style={{ fill: '#231815' }}
      d="M155.41,44.72c-6.16,0-9.91-1.54-14.49-6l4.33-6.29a16.83,16.83,0,0,0,5.06,3.71,12,12,0,0,0,5.14,1A6.38,6.38,0,0,0,161.9,31c0-2.95-2.08-3.76-6.22-5.36l-2.92-1.15c-6.91-2.77-9.49-6-9.49-12C143.27,6.85,147.47.8,156.7.8A19.61,19.61,0,0,1,168,4l-3.64,6.46a15.55,15.55,0,0,0-7.79-2c-2.33,0-4.9,1.34-5.3,3.89s1.36,4.52,4.77,5.74l3,1.1c6.06,2.21,11.5,4.84,11.5,12.52S164.34,44.72,155.41,44.72Z"
    />
    <path
      style={{ fill: '#004385' }}
      d="M43.39,0C37.32,0,33.26,2.65,29.93,8.83h0C26.6,2.65,22.55,0,16.47,0H0V5.71H14.38c6.95,0,12.41,5.46,12.7,12.7v1.24H16.7C6.87,19.65,0,26.36,0,35.87V45H16.47c6.08,0,10.13-2.65,13.46-8.83h0C33.26,42.35,37.32,45,43.39,45H59.85V39.29H45.48c-6.95,0-12.41-5.46-12.69-12.7V25.35H43.18c9.82,0,16.68-6.78,16.68-16.54V0ZM27.08,26.59c-.29,7.24-5.75,12.7-12.7,12.7H5.72V36.88c0-6.79,4.94-11.53,12-11.53h9.34ZM54.15,5.71V8.57c0,6.34-4.95,11.08-12,11.08H32.79V18.41c.28-7.24,5.74-12.7,12.69-12.7Z"
    />
    <path
      style={{ fill: '#f7b63d' }}
      d="M54.15,5.71V8.57c0,6.34-4.95,11.08-12,11.08H32.79V18.41c.28-7.24,5.74-12.7,12.69-12.7Z"
    />
    <path
      style={{ fill: '#a9ce52' }}
      d="M27.08,26.59c-.29,7.24-5.75,12.7-12.7,12.7H5.72V36.88c0-6.79,4.94-11.53,12-11.53h9.34Z"
    />
  </svg>
);

export default Njs;
