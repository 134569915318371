import React from 'react';
import Sticky from 'react-stickynode';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MDXLink from '@/components/MDXLink';
import useIntercomBanner from '@/hooks/useIntercomBanner';
import usePageContext from '@/hooks/usePageContext';
import Logo from '../../components/Logo';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';
import {
  HeaderInner,
  HeaderLeft,
  HeaderLink,
  HeaderLinkContainer,
  HeaderLinkWrapper,
  HeaderLogo,
  HeaderMain,
  HeaderNavigationItem,
  HeaderNavigationMenuButton,
  HeaderNavigationMenuContainer,
  HeaderNavigationMenuLink,
  HeaderOuter,
  HeaderWrapper,
  NavigationMenuToggle,
} from './Header.style';

const HeaderLogoLink = HeaderLogo.withComponent(MDXLink);
const LinkItem = HeaderLink.withComponent(MDXLink);
const Link = HeaderNavigationMenuLink.withComponent(MDXLink);
const LinkButton = HeaderNavigationMenuButton.withComponent(MDXLink);

type Props = {
  data: DataContainsNode;
  calendlyModalIsOpen?: boolean;
  updateCalendlyModalIsOpen?: (bool: boolean) => void;
  promotionalPage?: 'gen' | 'remax' | 'immovlan';
};

export const BaseHeader: React.FC<Props> = ({
  data,
  calendlyModalIsOpen,
  updateCalendlyModalIsOpen,
  promotionalPage,
}) => {
  const { theme } = usePageContext();
  const { frontmatter } = useTransformNode(data);
  const { offset: stickyTop } = useIntercomBanner();

  // NOTE: react-stickynode seems to update the top offset only on scroll or resize in fixed state,
  // so that update it by directly changing the element styles.
  React.useEffect(() => {
    const stickyEl = document.querySelector<HTMLElement>(
      '.sticky-inner-wrapper',
    );
    if (
      stickyEl &&
      stickyEl.style.position === 'fixed' &&
      stickyEl.style.transform.startsWith('translate3d')
    ) {
      stickyEl.style.transform = `translate3d(0px, ${stickyTop}px, 0px)`;
    }
  }, [stickyTop]);

  const navigationLinks = frontmatter?.navigation_contents;
  const headerLinkContents = frontmatter?.linkContents;
  const [navigationMenuIsOpen, updateNavigationMenuIsOpen] =
    React.useState(false);
  const navigationItems = navigationLinks?.map((link) => {
    return link?.type === 'button' ? (
      <HeaderNavigationItem key={link?.name}>
        <LinkButton
          theme={theme}
          href={link?.path}
          buttonTheme={link?.buttonTheme}
          className="navigationanchorlink"
          data-cy={
            theme === 'tours'
              ? promotionalPage
                ? 'toursHeaderCheckout'
                : 'toursHeaderSignup'
              : `${theme}Header${link?.name}`
          }
        >
          {link?.text}
        </LinkButton>
      </HeaderNavigationItem>
    ) : link?.type === 'calendlyButton' ? (
      <HeaderNavigationItem key={link?.name}>
        <HeaderNavigationMenuButton
          theme={theme}
          buttonTheme={link?.buttonTheme}
          className="navigationanchorlink"
          data-cy={theme ? `${theme}HeaderVirtualDemo` : 'HeaderVirtualDemo'}
          onClick={() => {
            updateCalendlyModalIsOpen?.(!calendlyModalIsOpen);
          }}
        >
          {link?.text}
        </HeaderNavigationMenuButton>
      </HeaderNavigationItem>
    ) : (
      <HeaderNavigationItem key={link?.name}>
        <Link
          href={link?.path}
          theme={theme}
          className="navigationanchorlink"
          data-cy={`header${link?.name?.replace(/\s+/g, '')}`}
        >
          {link?.text}
        </Link>
      </HeaderNavigationItem>
    );
  });

  const headerLinkItems = headerLinkContents?.map((item, i) => {
    return (
      <LinkItem
        key={i}
        href={item?.path}
        theme={theme}
        className="navigationanchorlink"
        data-cy={
          theme
            ? `${theme}Header${item?.name?.replace(/\s+/g, '')}`
            : `header${item?.name?.replace(/\s+/g, '')}`
        }
      >
        {item?.text}
      </LinkItem>
    );
  });

  return (
    <>
      {headerLinkItems ? (
        <HeaderLinkWrapper>
          <HeaderLinkContainer>{headerLinkItems}</HeaderLinkContainer>
        </HeaderLinkWrapper>
      ) : null}
      <HeaderWrapper className={navigationMenuIsOpen ? '-menu-open' : ''}>
        <Sticky top={stickyTop} innerZ={9999} activeClass="sticky-active">
          <HeaderMain className="headerdiv">
            <HeaderOuter>
              <HeaderInner>
                <HeaderLeft>
                  <HeaderLogoLink
                    href={
                      promotionalPage
                        ? promotionalPage !== 'immovlan'
                          ? `/tours/${promotionalPage}/sc2`
                          : `/tours/${promotionalPage}`
                        : frontmatter?.topLink
                    }
                    data-cy={theme ? `${theme}HeaderLogo` : 'headerLogo'}
                  >
                    {theme ? <Logo name={theme} /> : <Logo name="others" />}
                  </HeaderLogoLink>
                </HeaderLeft>
                <HeaderNavigationMenuContainer
                  data-expanded={!!navigationMenuIsOpen}
                >
                  {navigationItems}
                  {navigationMenuIsOpen ? (
                    <li>{headerLinkItems ?? null}</li>
                  ) : null}
                </HeaderNavigationMenuContainer>
                <NavigationMenuToggle
                  data-cy={theme ? `${theme}HeaderToggle` : 'HeaderToggle'}
                  onClick={() =>
                    updateNavigationMenuIsOpen(!navigationMenuIsOpen)
                  }
                >
                  {navigationMenuIsOpen ? (
                    <FontAwesomeIcon
                      icon={faTimes}
                      style={{
                        display: 'block',
                        margin: '0 auto',
                        marginTop: '2px',
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faBars}
                      style={{
                        display: 'block',
                        margin: '0 auto',
                        marginTop: '2px',
                      }}
                    />
                  )}
                </NavigationMenuToggle>
              </HeaderInner>
            </HeaderOuter>
          </HeaderMain>
        </Sticky>
      </HeaderWrapper>
    </>
  );
};
