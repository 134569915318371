import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { DataContainsNode } from '../../hooks/useTransformer';
import { BaseHeader } from './BaseHeader';

const HEADER_QUERY = graphql`
  query HiddenProjectsHeader {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          theme: { eq: "projects" }
          templateKey: { eq: "header" }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            topLink
            theme
            telNumber
            linkContents {
              name
              path
              text
            }
            navigation_contents {
              name
              text
              path
              type
              buttonTheme
            }
          }
        }
      }
    }
  }
`;

type Props = {
  calendlyModalIsOpen?: boolean;
  updateCalendlyModalIsOpen?: (bool: boolean) => void;
};

export const ProjectsHeader: React.FC<Props> = ({
  calendlyModalIsOpen,
  updateCalendlyModalIsOpen,
}) => {
  const data = useStaticQuery<GatsbyTypes.HiddenProjectsHeaderQuery>(
    HEADER_QUERY,
  ) as DataContainsNode;

  return (
    <BaseHeader
      data={data}
      calendlyModalIsOpen={calendlyModalIsOpen}
      updateCalendlyModalIsOpen={updateCalendlyModalIsOpen}
    />
  );
};
