import styled from 'styled-components';
import MDXLink from '@/components/MDXLink';

export const Box = styled.div`
  box-sizing: border-box;
`;
export const Text = styled(Box)`
  padding: ${(27 - 18) / 2}px 0;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  @media (min-width: 560px) and (max-width: 768px) {
    font-size: 16px;
  }
`;
export const TextLink = styled(Text.withComponent(MDXLink))`
  display: block;
  color: currentColor;
  text-decoration: none;
`;

export const MenuTitle = styled(Text)`
  font-weight: 700;
`;
export const ActiveText = styled(Text)`
  color: #ffffff4d;
`;
export const CopyrightText = styled(Text)`
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  @media (max-width: 768px) {
    font-size: inherit;
    line-height: 27px;
  }
`;
export const TextLinkWithDecoration = styled(TextLink)`
  @media (max-width: 768px) {
    text-decoration: none;
  }
`;

export const Separator = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: currentColor;
`;

export const ListBase = styled(Box.withComponent('ul'))`
  padding: 0;
  margin: 0;
  list-style: none;
`;
export const MenuBoxList = styled(ListBase)`
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 48px 1em;
  }
  & > li:not(:first-of-type) {
    margin-left: 50px;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }
`;

export const LogoLink = styled(TextLink)`
  display: inline-flex;
  align-items: center;
  width: auto;
  height: 36px;
  > svg {
    height: 100%;
    fill: #fff;
  }
`;

export const TopLogoLink = styled(TextLink)`
  display: flex;
  align-items: center;
  width: 104px;
  height: 100%;
  margin-right: 24px;
  > svg {
    width: 100%;
    fill: #fff;
  }
  @media (max-width: 768px) {
    margin-right: 0px;
    margin-bottom: 0px;
  }
`;

export const LogoSvg = styled(Box.withComponent('svg'))`
  height: 24px;
`;
export const SvgLink = styled(TextLink)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SnsLink = styled(SvgLink)`
  &:not(:first-of-type) {
    margin-left: 12px;
    @media (max-width: 768px) {
      margin-left: 18px;
    }
  }
`;
export const SnsSvg = styled(Box.withComponent('svg'))`
  height: 13px;
  @media (max-width: 768px) {
    height: 20px;
  }
`;
export const StoreButton = styled(SvgLink)`
  &:not(:first-of-type) {
    margin-left: 8px;
    @media (max-width: 768px) {
      margin-left: 13px;
    }
  }
`;
export const StoreSvg = styled(Box.withComponent('svg'))`
  height: 40px;
`;
export const LogoImage = styled(Box.withComponent('img'))`
  height: 18px;
  @media (max-width: 768px) {
    height: 24px;
  }
`;

export const AuthBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  margin-left: auto;
  @media (max-width: 768px) {
    align-self: unset;
    width: 100%;
  }
`;
export const ContentBox = styled(Box)<{
  orderOnMobile: number;
  marginTopOnMobile: number;
}>`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    order: ${({ orderOnMobile }) => orderOnMobile};
    margin-top: ${({ marginTopOnMobile }) => marginTopOnMobile}px;
  }
`;
export const ContainerBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const FooterBox = styled(Box)`
  padding: 50px max(30px, (100% - 1005px) / 2) 88px;
  color: #ffffff;
  background-color: #908c8c;
  @media (max-width: 768px) {
    padding-bottom: 43px;
  }
`;

const ButtonBase = styled.button`
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-align: center;
`;

export const RoundOutlineButton = styled(ButtonBase)`
  && {
    width: 170px;
    height: 34px;
    font-size: 16px;
    color: currentColor;
    text-decoration: none;
    letter-spacing: 0;
    border: 1px solid currentColor;
    border-radius: 9999px;
    @media (max-width: 768px) {
      width: 100%;
      height: 44px;
      font-size: 16px;
      border-radius: ${44 / 2}px;
    }
  }
`;
