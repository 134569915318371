import { css } from 'styled-components';

const SharedCss = css`
  font-weight: 300;
  line-height: 1.6;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  dl,
  dt,
  dd,
  ol,
  ul,
  li {
    padding: 0;
    margin: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  ol,
  ul,
  li {
    list-style-type: none;
  }

  img {
    max-width: 100%;
  }

  p {
    font-size: 14px;
  }

  a:link {
    color: #0093bb;
  }

  a:hover {
    color: #0093bb;
  }

  a:visited {
    color: #0093bb;
  }

  a:active {
    color: #0093bb;
  }

  @media (min-width: 1024px) {
    p {
      font-size: 16px;
      line-height: 2;
    }
  }
`;

export default SharedCss;
