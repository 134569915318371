import React from 'react';

const Shimizu: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.13 45" height={45}>
    <path
      style={{ fill: '#005eac' }}
      d="M2.71,12a6.75,6.75,0,0,1,6.12,2.22,2.66,2.66,0,0,1-.61,4c-1.18.67-2.38-.35-2.88-1.54a12.7,12.7,0,0,0-2.63-4.37ZM0,19.76a23.21,23.21,0,0,1,2.28,4c.43,1,1.37,2.38,2.86,1.51a2.49,2.49,0,0,0,.58-3.75,7.34,7.34,0,0,0-5.72-2ZM.4,35.85C3,37.39,3.13,37.64,2.67,40.38A11,11,0,0,0,2.47,42a2.56,2.56,0,0,0,2.32,2.71,2.25,2.25,0,0,0,2.38-2.32c0-1.87-.89-2.86-.89-4.88a24.63,24.63,0,0,1,.75-5C7.38,31,9.26,22.53,9.46,21.53l-.27-.1c-.86,2-5.26,11.7-6.38,13.42-.41.63-.59.73-1.18.73-.22,0-.94-.06-1.23-.08ZM33.87,24.67a14.37,14.37,0,0,0-3.1-2.49.31.31,0,0,0-.5.08c-.19.23-1.6,2.27-1.6,2.27H23.21V21.29h6.42a.92.92,0,0,0,.8-.27.4.4,0,0,0,.08-.56,14.13,14.13,0,0,0-3.1-2.5.32.32,0,0,0-.5.09c-.19.23-1.6,2.27-1.6,2.27h-2.1V17.27h7.56a.94.94,0,0,0,.8-.28.4.4,0,0,0,.08-.56,14.7,14.7,0,0,0-2.88-2.77.31.31,0,0,0-.49.09c-.19.23-1.84,2.54-1.84,2.54H23.21c0-1.17.13-1.36.8-1.76.83-.51.81-.77-.06-1.14a20.19,20.19,0,0,0-5.51-1.25,23.19,23.19,0,0,1,.32,4.15H12.55a2.92,2.92,0,0,1-1-.16l-.18.19.46.95h7v3.05H13.39a4.15,4.15,0,0,1-1-.17l-.17.2.49.94h6.09v3.24H11.09a4.86,4.86,0,0,1-1.21-.12l-.17.22.37.88H33a1,1,0,0,0,.8-.27A.41.41,0,0,0,33.87,24.67ZM20.18,40.74l5,.1c.58,0,.78-.22.78-.58V37.17H16.49v4.46a3.47,3.47,0,0,1-.32,1.78,2.94,2.94,0,0,1-2.24,1.07c-1.43,0-2.1-.6-1.87-2.19a48.39,48.39,0,0,0,.45-6.67V26.37a15,15,0,0,1,3.73,1.53H26l.72-1.11a.51.51,0,0,1,.71-.14c1.19.66,2.17,1.13,3.41,1.7a.83.83,0,0,1,.2,1.4,10.81,10.81,0,0,1-.91.66v9.72c0,2.11-.2,2.92-.69,3.49-.66.79-2.11,1.3-4.13,1.3-.53-1-.5-1.75-5.16-3.94ZM16.49,28.91V32h9.44V28.91Zm9.44,7.22V33H16.49v3.11Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M35.06,39.46a33.87,33.87,0,0,0,9-16.85H37.17l-.77-1,.13-.2a5.06,5.06,0,0,0,1.21.12l6.55,0A17.78,17.78,0,0,1,45.51,20c.31-.27.44-.25.84,0s2.6,1.94,3,2.29c.91.8.83,1.29-.42,1.66a29.94,29.94,0,0,1-13.5,16ZM70,36.24c-4.51-2.35-9.34-6.39-11.72-10.69a65.62,65.62,0,0,0,6.47-2.31,1.79,1.79,0,0,1,.76-.12c.86.13,1.24.45,2-.14.34-.28.29-.86-.22-1.25l-4.11-3.18a.5.5,0,0,0-.81.18,13.45,13.45,0,0,1-4.48,6.1,22,22,0,0,1-3.19-8.66c0-.86,0-.92.88-1.4,1.29-.72.77-1.37-.26-1.64C54.74,13,50.32,12.05,50,12c.37,3.77.62,7.89.62,12V38.09c0,1.25-.48,1.73-1.82,1.78a37.69,37.69,0,0,1-5.12-.19l-.07.36c4.3,1.37,5.31,2.44,6.13,4.88a5.52,5.52,0,0,0,3.63-1.06c1.26-.91,1.48-1.71,1.48-3.89V21.23h.08c1.12,7.29,4.89,14.45,9.27,18.64A17.13,17.13,0,0,1,70,36.53Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M106,38.78a18.42,18.42,0,0,0-3.4,4.93c-4.16.25-8.37.1-12.14,0-5.52-.17-7.78-1-11.65-4.39-1.92,2.18-5.68,5-8.17,5.7l-.35-.53a23.17,23.17,0,0,0,6.36-7.61,36,36,0,0,0-1-4.21,24.59,24.59,0,0,0-1.56,3.44.8.8,0,0,1-1.38.12,28,28,0,0,0-2-2.21.41.41,0,0,1,.11-.6A18.25,18.25,0,0,0,76,29a4.56,4.56,0,0,1-2.15-.08c-.63-.15-.88-.59-.63-1.43s2.82-9.21,3.34-10.77H72.26l-.58-1,.21-.25a4,4,0,0,0,1.29.23h3.45a8.27,8.27,0,0,1,1.05-1.28.71.71,0,0,1,1-.08c.21.13,2.09,1.25,2.59,1.53,1.64.92,1.55,1.64-.75,2.6-.23.76-1.48,5.26-2.07,7.27H80.4a9,9,0,0,1,1.16-1.26c.31-.2.46-.22.86,0A16.77,16.77,0,0,1,85,26.36c1,.92.92,1.35-.86,2.1A30.79,30.79,0,0,1,80,37.8c1.25,1.18,3.32,1.85,6.08,2a75.26,75.26,0,0,0,19.78-1.34ZM78.09,26.9a4.18,4.18,0,0,1-1.33,1.82c1.14.28,1.3,1,.72,1.83a1.63,1.63,0,0,0-.33,1.19,6.32,6.32,0,0,0,.62,2.47,30.92,30.92,0,0,0,2.31-7.31Zm26-5h-3.92c0,1.06,0,1.57.12,2.65.12,1.59.18,2.09-2.28,3l2.29,2c.67.59.53,1.18-.35,1.18H93.44V34h4l2.06-2.23a.91.91,0,0,1,1.34-.09c.24.17,2.34,1.8,2.63,2.06.77.69.34,1.31-.66,1.31H93.44v2.18c0,2-2.07,2.43-2.86,2.43-1.36,0-1.91-.38-1.91-1.83V35H82.86l-.5-1,.2-.25a7,7,0,0,0,1.61.18h4.5V30.73H85.16l-.5-1,.2-.25a7,7,0,0,0,1.62.17h2.19V26.31H85.5l-.51-1,.21-.25a6.91,6.91,0,0,0,1.61.18h1.86V21.87H81l-.5-1,.2-.25a6.91,6.91,0,0,0,1.61.18h6.37v-3H83.5l-.5-1,.2-.25a7.66,7.66,0,0,0,1.62.17h3.85V13.88a8.3,8.3,0,0,0-.34-2.05l5.59,1.54a.79.79,0,0,1,.31,1.45c-.27.22-.79.58-.79.58v1.33H96s.83-1,1.07-1.26a.8.8,0,0,1,1-.24c.3.09,2.35,1.22,2.72,1.45.62.38.66,1,.13,1.38l-.79.47v2c.39-.47,1.09-1.29,1.23-1.44.41-.45.75-.47,1.14-.15.18.15,1.91,1.53,2.14,1.73C105.42,21.31,105,21.87,104,21.87Zm-10.6-1.05H96v-3H93.44Zm0,4.44H96V21.87H93.44Zm3.8,2.29c-.7-.14-.89-.32-1.14-1.24H93.44v3.36h1.75Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M110.87,15.07h3.58l1.61-2.13a.72.72,0,0,1,1.13,0c.16.16,1.83,1.79,2,1.93.61.54.4,1.08-.33,1.08h-9.09l-.49-.83.18-.22A4.65,4.65,0,0,0,110.87,15.07Zm-3.26,4.31-.13.27.71,1h12.9c.73,0,.94-.55.32-1.08l-2.52-2.32c-.42-.38-.87-.38-1.13,0-.13.21-1.26,2.27-1.26,2.27h-7.41A6.8,6.8,0,0,1,107.61,19.38Zm2.45,4.33-.21.29.48.74h9.06c.73,0,.94-.54.33-1.08l-2.07-1.8c-.41-.37-.88-.35-1.13,0s-1.28,2-1.28,2h-3.7A4.23,4.23,0,0,1,110.06,23.71Zm.49,4.18-.17.21.35.84h8.6c.76,0,.89-.59.32-1.08l-1.9-1.61c-.41-.36-.77-.34-1.06.05-.13.18-1.44,1.79-1.44,1.79h-3.38A3.7,3.7,0,0,1,110.55,27.89Zm10.08,6.42c0,2.24.07,3.91.23,6.13.1,1.39-1.25,2-2.5,2a1.76,1.76,0,0,1-2-1.62h-2.68v1.51c0,1.62-.87,2.26-2.7,2.26-1.29,0-1.74-.81-1.6-2A61.93,61.93,0,0,0,109.47,31a19.63,19.63,0,0,1,4.33,1.75h2.81s.93-1.28,1.07-1.45a.66.66,0,0,1,.9-.15c.22.1,2.27,1.24,2.58,1.49C121.78,33.17,121.52,34,120.63,34.31Zm-6.94,5.4h2.68V33.77h-2.68ZM122.91,13c1.18,6.9-.19,11.93-3.2,14.47l.21.35c5.64-2.23,7.43-5.69,7.43-11.82h2.41v6.94c0,1.69,1.09,3.1,5.77,3.1,3.21,0,5-1.44,5-3.08,0-.83-1.15-.74-1.42-2-.11-.51-1-4.85-1-4.85h-.25s-1.21,5-1.34,5.36c-.19.49-.44.75-1.46.75-1.2,0-1.47-.37-1.53-1.26,0-.5,0-3.69,0-3.69a7.8,7.8,0,0,0,.7-.49.87.87,0,0,0,0-1.44,18.6,18.6,0,0,0-2.58-2,.47.47,0,0,0-.72.11c-.19.24-1,1.55-1,1.55h-2.2Zm18.22,27v.31a16.48,16.48,0,0,0-4.72,4.07,24.36,24.36,0,0,1-6.92-5,24.73,24.73,0,0,1-13.84,5.55l-.17-.47c5.21-1.88,9.16-4.4,11.91-7.78a21,21,0,0,1-3-7.55h-3l-.51-1,.17-.25a25.36,25.36,0,0,0,3.08.22h7.32l.95-1.25a.87.87,0,0,1,1.38-.25c.4.25,2.28,1.45,2.86,1.93s.66,1,0,1.44c-.18.12-1,.54-1.29.66a20.14,20.14,0,0,1-3.38,6.09A29.06,29.06,0,0,0,141.13,40ZM129,34.35a20.9,20.9,0,0,0,2.19-5.23h-5.72A16.28,16.28,0,0,0,129,34.35Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M136.43,5.58c0,3.08-3.73,5.58-8.32,5.58s-8.33-2.5-8.33-5.58S123.51,0,128.11,0,136.43,2.5,136.43,5.58Zm-8.18-4.1c-3.15-.11-5.78,1.64-5.85,3.9s2.41,4.18,5.56,4.29,5.77-1.63,5.85-3.89S131.4,1.59,128.25,1.48Zm-3,6.36a1.21,1.21,0,0,0,1.27.43,8.53,8.53,0,0,0,2.45-1.53,8.51,8.51,0,0,0,2.08-2A1.22,1.22,0,0,0,129.62,3a8.24,8.24,0,0,0-2.45,1.52,8.51,8.51,0,0,0-2.08,2A1.2,1.2,0,0,0,125.2,7.84Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M31.09,6.62c0,.3.16.88,1.15.88.53,0,1.13-.13,1.13-.71,0-.42-.41-.53-1-.67L31.81,6c-.88-.21-1.72-.4-1.72-1.6,0-.6.32-1.67,2.08-1.67s2.11,1.09,2.11,1.76H33.2c0-.24-.12-.82-1.11-.82-.43,0-.94.16-.94.65s.34.51.57.57L33,5.2c.74.18,1.42.49,1.42,1.46,0,1.63-1.66,1.77-2.14,1.77-2,0-2.32-1.14-2.32-1.81Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M36.2,5.86V8.29H35.07V2.87H36.2V4.92h2.12v-2h1.13V8.29H38.32V5.86Z"
    />
    <path style={{ fill: '#005eac' }} d="M41.57,8.29H40.44V2.87h1.13Z" />
    <path
      style={{ fill: '#005eac' }}
      d="M48.71,8.29h-1V3.76h0L46.54,8.29H44.92L43.78,3.76h0V8.29H42.56V2.87h2l1.13,4.27h.06l1.1-4.27h1.83Z"
    />
    <path style={{ fill: '#005eac' }} d="M50.83,8.29H49.7V2.87h1.13Z" />
    <path
      style={{ fill: '#005eac' }}
      d="M55.74,8.29H51.5v-1l2.8-3.5H51.58v-1h4.14v.89L52.89,7.33h2.85Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M60.84,6.4c0,1.42-.86,2-2.23,2a2.35,2.35,0,0,1-1.72-.65,2,2,0,0,1-.42-1.32V2.87h1.15V6.39a1,1,0,0,0,1,1.08c.78,0,1.1-.37,1.1-1V2.87h1.15Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M67.5,4.64a1.09,1.09,0,0,0-1.19-.93c-.55,0-1.35.36-1.35,1.89,0,1,.39,1.85,1.35,1.85a1.14,1.14,0,0,0,1.19-1h1.14a2.26,2.26,0,0,1-2.36,2c-1.44,0-2.47-1-2.47-2.84S64.9,2.72,66.3,2.72a2.16,2.16,0,0,1,2.34,1.92Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M71.71,2.72a2.53,2.53,0,0,1,2.6,2.86,2.62,2.62,0,1,1-5.21,0A2.54,2.54,0,0,1,71.71,2.72Zm0,4.75c.61,0,1.47-.37,1.47-1.89s-.86-1.89-1.47-1.89-1.47.37-1.47,1.89S71.1,7.47,71.71,7.47Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M75.07,2.87h2.74A1.53,1.53,0,0,1,79.52,4.4a1.3,1.3,0,0,1-.84,1.29c.44.2.68.37.68,1.38,0,.79,0,1,.25,1.08v.14H78.35a3.86,3.86,0,0,1-.12-1.1c0-.74,0-1-.88-1H76.18V8.29H75.07Zm2.44,2.38c.58,0,.87-.2.87-.74,0-.28-.12-.7-.79-.7H76.18V5.25Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M81.44,8.29H80.31V2.87h2.47A1.59,1.59,0,0,1,84.47,4.6a1.66,1.66,0,0,1-1.73,1.73h-1.3Zm1-2.89c.85,0,.85-.61.85-.85,0-.53-.3-.74-1-.74h-.9V5.4Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M87.41,2.72A2.54,2.54,0,0,1,90,5.58a2.62,2.62,0,1,1-5.21,0A2.54,2.54,0,0,1,87.41,2.72Zm0,4.75c.61,0,1.47-.37,1.47-1.89S88,3.69,87.41,3.69s-1.48.37-1.48,1.89S86.79,7.47,87.41,7.47Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M90.76,2.87H93.5A1.53,1.53,0,0,1,95.21,4.4a1.29,1.29,0,0,1-.84,1.29c.45.2.69.37.69,1.38,0,.79,0,1,.24,1.08v.14H94.05a3.52,3.52,0,0,1-.12-1.1c0-.74-.05-1-.89-1H91.87V8.29H90.76ZM93.2,5.25c.59,0,.88-.2.88-.74,0-.28-.13-.7-.8-.7H91.87V5.25Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M96.66,8.29H95.49l1.93-5.42h1.4l1.92,5.42H99.43l-.37-1.18h-2Zm.74-2h1.38L98.11,4h0Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M103.06,8.29h-1.13V3.83h-1.64v-1h4.41v1h-1.64Z"
    />
    <path style={{ fill: '#005eac' }} d="M106.41,8.29h-1.13V2.87h1.13Z" />
    <path
      style={{ fill: '#005eac' }}
      d="M109.77,2.72a2.54,2.54,0,0,1,2.6,2.86,2.62,2.62,0,1,1-5.21,0A2.54,2.54,0,0,1,109.77,2.72Zm0,4.75c.61,0,1.47-.37,1.47-1.89s-.86-1.89-1.47-1.89-1.48.37-1.48,1.89S109.15,7.47,109.77,7.47Z"
    />
    <path
      style={{ fill: '#005eac' }}
      d="M116.65,2.87h1V8.29h-1.4L114,4.08h0V8.29h-1V2.87h1.42l2.16,4.07h0Z"
    />
  </svg>
);

export default Shimizu;
