import React from 'react';

const NipponKoei: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.95 14" height={20}>
    <path
      style={{ fill: '#1f75c0' }}
      d="M12.14,10.23c.08-.21.13-.33.16-.44.88-3,1.77-6.06,2.64-9.09.08-.31.21-.44.55-.42.8,0,1.6,0,2.41,0l.43,0c-.16.55-.31,1-.46,1.54q-1.74,5.73-3.49,11.45a.42.42,0,0,1-.48.37c-1.33,0-2.66,0-4-.07a1.61,1.61,0,0,1-1.64-1.27c-.25-.91-.4-1.84-.61-2.76Q7,6.83,6.4,4.09a.52.52,0,0,0-.2-.29c-.06.16-.13.32-.18.49q-1.33,4.5-2.66,9a.43.43,0,0,1-.51.37c-.93,0-1.86,0-2.85,0,0-.15.05-.26.08-.36Q2,7,3.92.68a.46.46,0,0,1,.54-.4C5.74.3,7,.28,8.3.29a1.57,1.57,0,0,1,1.82,1.44l1.41,6.16c.15.67.31,1.33.48,2C12,10,12.07,10.05,12.14,10.23Z"
    />
    <path
      style={{ fill: '#1f75c0' }}
      d="M68.12,13.66c.26-.88.51-1.69.75-2.5C69.94,7.65,71,4.15,72.08.64a.41.41,0,0,1,.49-.36h3.88C77.56.3,78,.62,78.24,1.7c.53,2.23,1,4.47,1.55,6.7.11.5.23,1,.35,1.49,0,.1.07.19.14.37.07-.19.11-.29.14-.4.89-3,1.77-6.07,2.64-9.12.1-.35.24-.48.62-.47.91,0,1.81,0,2.79,0l-.28,1q-1.83,6-3.67,12c-.08.27-.19.38-.48.37-1.28,0-2.56,0-3.84-.06a1.83,1.83,0,0,1-1.92-1.71L74.54,4.1a.84.84,0,0,0-.18-.35c0,.14-.12.28-.16.42-.9,3-1.81,6.09-2.7,9.14a.41.41,0,0,1-.49.35Z"
    />
    <path
      style={{ fill: '#1f75c0' }}
      d="M58.75,14a21.11,21.11,0,0,1-2.53-.35A2.66,2.66,0,0,1,54.1,11a9.48,9.48,0,0,1,.56-3.46,18.27,18.27,0,0,1,1.82-4.18A6.61,6.61,0,0,1,61.56.14,17.13,17.13,0,0,1,66,.15,3.47,3.47,0,0,1,67.86,1a2.83,2.83,0,0,1,.85,2.39,16.23,16.23,0,0,1-2.56,7.45,6.35,6.35,0,0,1-4.8,3C60.57,13.89,59.78,13.92,58.75,14ZM60,11.61c1.73,0,2.23-.35,2.81-2,.48-1.36.94-2.72,1.41-4.09.21-.61.43-1.23.58-1.86a1,1,0,0,0-.88-1.25,11.7,11.7,0,0,0-2.05,0A2,2,0,0,0,60.15,4c-.51,1.42-1,2.85-1.48,4.29a18.56,18.56,0,0,0-.57,1.93,1,1,0,0,0,1,1.35C59.4,11.58,59.68,11.59,60,11.61Z"
    />
    <path
      style={{ fill: '#1f75c0' }}
      d="M111,14a23.08,23.08,0,0,1-2.49-.35,2.64,2.64,0,0,1-2.11-2.59A9.16,9.16,0,0,1,107,7.47,17.07,17.07,0,0,1,109,3,6.57,6.57,0,0,1,113.82.14a17,17,0,0,1,4.45,0,2.79,2.79,0,0,1,2.67,2.44,7,7,0,0,1-.12,2.28,17.7,17.7,0,0,1-2.27,5.82,6.51,6.51,0,0,1-5,3.11C112.78,13.89,112,13.92,111,14Zm1.26-2.37c1.71,0,2.17-.33,2.75-1.92.52-1.41,1-2.83,1.48-4.25a13.68,13.68,0,0,0,.55-1.83,1,1,0,0,0-1-1.22c-.41-.05-.82-.05-1.22-.06A2.31,2.31,0,0,0,112.43,4c-.42,1.19-.84,2.39-1.24,3.59-.29.86-.58,1.72-.8,2.59a1,1,0,0,0,.81,1.28A7.64,7.64,0,0,0,112.27,11.63Z"
    />
    <path
      style={{ fill: '#1f75c0' }}
      d="M24.28,13.63c.11-.4.21-.77.33-1.15C25.81,8.55,27,4.61,28.21.67c.09-.31.23-.39.54-.39,2.32,0,4.64,0,7,0a7.23,7.23,0,0,1,2,.32,2.66,2.66,0,0,1,1.84,3.05A6.63,6.63,0,0,1,37,8.48a7.38,7.38,0,0,1-4.36,1.35c-.88,0-1.75,0-2.62,0a.37.37,0,0,0-.42.31c-.34,1.06-.69,2.12-1,3.19-.06.18-.13.29-.36.29h-3.7Zm5.9-5.49a2.13,2.13,0,0,0,.39,0c.4,0,.81,0,1.21-.06a3.5,3.5,0,0,0,2.43-1.31A7.05,7.05,0,0,0,35.37,4c.24-1-.2-1.55-1.26-1.56-.59,0-1.17,0-1.75,0a.47.47,0,0,0-.56.41c-.41,1.38-.84,2.76-1.26,4.14C30.43,7.32,30.31,7.71,30.18,8.14Z"
    />
    <path
      style={{ fill: '#1f75c0' }}
      d="M38.58,13.62l.91-3c1-3.28,2-6.56,3-9.84.12-.39.27-.53.69-.53,2.29,0,4.57,0,6.85,0a7.1,7.1,0,0,1,2.09.35,2.68,2.68,0,0,1,1.76,3,7,7,0,0,1-1.66,4,5.89,5.89,0,0,1-3.79,2c-1.33.18-2.69.17-4,.22a.49.49,0,0,0-.56.41c-.31,1-.66,2.06-1,3.09-.06.18-.14.29-.36.29H38.81Zm5.93-5.41c.58,0,1.09,0,1.6-.1a3.46,3.46,0,0,0,2.68-1.68,8.22,8.22,0,0,0,.94-2.72.93.93,0,0,0-.84-1.24c-.72-.08-1.46,0-2.19-.08-.36,0-.51.11-.61.45-.48,1.65-1,3.29-1.49,4.94C44.57,7.89,44.55,8,44.51,8.21Z"
    />
    <path
      style={{ fill: '#1f75c0' }}
      d="M125.57,11.31h6.51c-.33.65-.56,1.24-.89,1.77a1.59,1.59,0,0,1-1.47.57h-9l-.17,0c.05-.17.08-.33.12-.48q1.89-6.21,3.77-12.42a.51.51,0,0,1,.61-.44h10.05l.09.1c-.33.58-.61,1.19-1,1.73a1.52,1.52,0,0,1-1.27.57c-1.44,0-2.89,0-4.33,0-.35,0-.52.1-.61.45-.18.72-.41,1.43-.62,2.15,0,.1,0,.22-.08.38h5.42a3,3,0,0,1-2.57,2.24c-1,.09-2,.07-3.07.09-.53,0-.53,0-.68.5Z"
    />
    <path
      style={{ fill: '#1f75c0' }}
      d="M108.2.14a9.72,9.72,0,0,0-2.15,2.66,8.11,8.11,0,0,1-2.67,2.76A10.69,10.69,0,0,1,102,6.1c-.12.05-.26.08-.46.14l3.38,7.42h-.74c-1,0-1.93,0-2.9-.07a1.75,1.75,0,0,1-1.57-1.11c-.42-1-.78-1.92-1.16-2.88s-.67-1.71-1-2.57c-.06-.16-.07-.28.13-.35a6.73,6.73,0,0,0,2.91-2.53c.48-.68.93-1.37,1.38-2.07A3.84,3.84,0,0,1,105.32.14C106.26.13,107.19.14,108.2.14Z"
    />
    <path
      style={{ fill: '#1f75c0' }}
      d="M133.47,13.64c.37-1.2.72-2.32,1.07-3.44l3-9.56c.06-.2.11-.36.38-.36h3.85l.21,0c-.16.55-.32,1.08-.48,1.61-1.17,3.78-2.35,7.55-3.51,11.33-.09.3-.21.41-.53.4-1.18,0-2.35,0-3.53,0Z"
    />
    <path
      style={{ fill: '#1f75c0' }}
      d="M92.64,13.66H90.89c-.46,0-.47,0-.34-.45.6-1.93,1.21-3.86,1.81-5.8.69-2.24,1.4-4.48,2.08-6.73.09-.29.21-.41.53-.4h3.56c.32,0,.38.09.28.39Q96.86,7,94.92,13.26c-.1.3-.22.42-.53.4C93.8,13.64,93.22,13.66,92.64,13.66Z"
    />
    <path
      style={{ fill: '#1f75c0' }}
      d="M17.12,13.66c.21-.68.39-1.3.58-1.91Q19.41,6.18,21.1.64c.06-.2.11-.36.39-.36H25.3l.21,0c-.15.51-.3,1-.45,1.48Q23.34,7.46,21.6,13.11c-.2.64-.1.54-.73.54H17.12Z"
    />
  </svg>
);

export default NipponKoei;
