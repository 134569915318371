import React from 'react';
import { Link } from 'gatsby';
import useLocale from '../hooks/useLocale';

type Props = {
  to: string;
};

// Use the globally available context to choose the right path
const LocalizedLink: React.FC<Props> = ({ to, ...props }) => {
  const { defaultLanguage, language } = useLocale();

  const isIndex = to === `/`;

  // If it's the default language, don't do anything
  // If it's another language, add the "path"
  // However, if the homepage/index page is linked don't add the "to"
  // Because otherwise this would add a trailing slash
  const path =
    language === defaultLanguage ? to : `/${language}${isIndex ? '' : `${to}`}`;

  if (path.match(/^(?=.*\?ref=).*$/)) {
    return <Link {...props} to={path} />;
  } else {
    return <Link {...props} to={path.replace(/\/*$/, '/')} />;
  }
  // TODO: Currently always add a trailing slash
};

export default LocalizedLink;
